// React
import { Fragment, useEffect, useState } from "react"

// API
import { useMutation, useQuery } from "react-query"
import { AtributApi, SettingApi } from "api"
import { Api } from "./api"

// Components
import { ActionButton, Calendar, Checkbox, CloseButton, FetchingStatus, Input, InputNumber, Loading, Select, Td, Terms } from "components"

// Form
import * as yup from "yup"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { Formik } from "formik"

// Functions
import { dateConvert, toast } from "functions"

// Third-Party Libraries
import { CustomNumberInput } from "../CustomNumberInput"

export function OrderReview({
  checkAvailable,
  is_departure,
  setting,
  waiver_type
}) {
  // Form
  const { control, getValues } = useFormContext()
  const is_date_available = useWatch({
    control,
    name: "is_date_available"
  })

  useEffect(() => {
    if (!is_date_available) {
      checkAvailable.mutateAsync({ booking_date: getValues("booking_date") })
    }

    // eslint-disable-next-line
  }, [])

  if (checkAvailable.isLoading) {
    return (
      <div style={{ height: "50vh" }}>
        <div className="h-100 flex justify-content-center items-center">
          <Loading color="primary" />
        </div>
      </div>
    )
  } else {
    return (
      <div className="max-w-[900px] mx-auto">
        <h4 className="text-center font-bold">Cart</h4>

        <section className="my-3">
          {!is_date_available && (
            <div className="alert alert-error rounded-lg text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
              <span>Your booking date is not available!</span>
            </div>
          )}

          <TableSection setting={setting} />
        </section>
  
        <div className="mb-2">
          <h5 className="font-bold">Guest Details</h5>

          <section className="grid md:grid-cols-4 gap-3">
            <section className="col-span-2">
              <Controller
                control={control}
                name="first_name"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="First Name"
                    placeholder="Enter First Name"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <section className="col-span-2">
              <Controller
                control={control}
                name="last_name"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Last Name"
                    placeholder="Enter Last Name"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <section className="col-span-2">
              <Nationality />
            </section>

            <section>
              <Controller
                control={control}
                name="phone"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <section>
              <Controller
                control={control}
                name="zip"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Zip/Post Code"
                    placeholder="Enter Zip/Post Code"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <section className="col-span-2">
              <Gender />
            </section>

            <section className="col-span-2">
              <Controller
                control={control}
                name="date_of_birth"
                render={({ field, fieldState }) => (
                  <Calendar
                    label="Date of Birth"
                    errors={fieldState.error?.message}
                    value={new Date(field.value)}
                    onChange={e => field.onChange(dateConvert(e).default.format)}
                  />
                )}
              />
            </section>

            <section className="col-span-2">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Email"
                    placeholder="Enter Email"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <section className="col-span-2">
              <Controller
                control={control}
                name="email_confirmation"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Email Confirmation"
                    placeholder="Enter Email Confirmation"
                    errors={fieldState.error?.message}
                  />
                )}
              />
            </section>
          </section>

          <section className="my-3 flex flex-col gap-3">
            <Controller
              control={control}
              name="terms"
              render={({ field, fieldState }) => (
                <Checkbox
                  ref={field.ref}
                  onChange={() => field.onChange(!field.value)}
                  value={field.value}
                  errors={fieldState.error?.message}
                >
                  I accept all FINNS Recreation Club <Terms.Condition />
                </Checkbox>
              )}
            />

            <Controller
              control={control}
              name="policy"
              render={({ field, fieldState }) => (
                <Checkbox
                  ref={field.ref}
                  onChange={() => field.onChange(!field.value)}
                  value={field.value}
                  errors={fieldState.error?.message}
                >
                  I understand and accept the FINNS Bali <Terms.Policy />
                </Checkbox>
              )}
            />

            {waiver_type !== "FITNESS" && (
              <Controller
                control={control}
                name="waiver"
                render={({ field, fieldState }) => (
                  <Checkbox
                    ref={field.ref}
                    onChange={() => field.onChange(!field.value)}
                    value={field.value}
                    errors={fieldState.error?.message}
                  >
                    I agree to the <Terms.SplashWaiver />
                  </Checkbox>
                )}
              />
            )}

            {is_departure && (
              <Fragment>
                <Controller
                  control={control}
                  name="no_transportation"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      onChange={() => field.onChange(!field.value)}
                      value={field.value}
                      errors={fieldState.error?.message}
                    >
                      I understand that this ticket does not include transportation
                    </Checkbox>
                  )}
                />

                <Controller
                  control={control}
                  name="additional_cost"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      onChange={() => field.onChange(!field.value)}
                      value={field.value}
                      errors={fieldState.error?.message}
                    >
                      I want to request airport transfer (additional charge, subject to availability)
                    </Checkbox>
                  )}
                />
              </Fragment>
            )}
          </section>
        </div>
      </div>
    )
  }
}

function TableSection({ setting }) {
  // Hooks
  const { control } = useFormContext()

  // Variables
  const _code = useWatch({
    control,
    name: "_code"
  })
  const qty_insurance = useWatch({
    control,
    name: "qty_insurance"
  })
  const ticket = useWatch({
    control,
    name: "ticket"
  })

  // API
  const getDataCurrency = useQuery(['dayPasses-payment-currency'], AtributApi.currency_rate)
  const fetchInsurance = useQuery(['setting', 'personal_insurance'], SettingApi.personal_insurance)

  // Variables
  const totalPrice = ticket.reduce(
    (previousValue, { pax, price }) => previousValue + (pax * price), 0
  )
  const totalDiscount = () => {
    let discount = 0

    if (_code.length === 0) {
      discount = 0
    } else {
      discount = ticket.reduce((acc, item) => {
        if (_code[0].promo.sub_type === "GLOBAL" || _code[0].promo.service_available.find(code => code.service_id === item.id)) {
          return acc += _code[0].discount_type === "PERCENTAGE" ? (item.price * (_code[0].amount / 100) * item.pax) : _code[0].amount
        }
    
        return acc
      }, 0)
    }

    return totalPrice - discount
  }
  const tax = totalDiscount() * setting.tax_services
  const insurance_price = qty_insurance * (fetchInsurance.data ?? 0)
  const grandTotal = totalDiscount() + tax + insurance_price

  return (
    <section className="overflow-x-auto">
      <table className="table border-y">
        <tbody>
          {ticket.map((val, index) => {
            return (
              <tr key={index}>
                <Td colSpan={2}>
                  <section className="flex gap-1">
                    <img className="mr-2 h-[60px]" alt={val.name} src={val.image} />

                    <section className="grow flex md:flex-row flex-col md:justify-between">
                      <section className="flex justify-start">
                        <section>
                          <div className="font-bold">{val.label}</div>
                          <div className="font-bold text-gray-400">x {val.pax}</div>
                        </section>
                      </section>

                      <section className="flex flex-col sm:items-end">
                        <div className="font-bold sm:text-right">IDR {(val.price * val.pax).toLocaleString()}</div>
                        <Qty index={index} />
                      </section>
                    </section>
                  </section>
                </Td>
              </tr>
            )
          })}

          <tr>
            <Td colSpan={2}>
              <div className="p-4 bg-gray-100 rounded-2xl flex flex-col gap-3">
                <div className="font-bold">Sub Total</div>

                <section className="flex justify-between gap-3">
                  <div>Subtotal</div>
                  <div className="font-bold">IDR {totalPrice.toLocaleString('id-ID')}</div>
                </section>
              </div>
            </Td>
          </tr>

          <Code />

          <tr>
            <Td>
              <div className="font-bold mr-1">Personal Insurance</div>
              <PersonalInsurance />
            </Td>
            <Td>
              <TdEnd>IDR {insurance_price.toLocaleString('id-ID')}</TdEnd>
            </Td>
          </tr>

          {setting.tax_services > 0 && (
            <tr>
              <Td>
                <div className="font-bold">Government Tax & Service Charge</div>
              </Td>
              <Td>
                <TdEnd>IDR {tax.toLocaleString('id-ID')}</TdEnd>
              </Td>
            </tr>
          )}

          {getDataCurrency.isLoading || getDataCurrency.isError ? (
            <tr>
              <Td colSpan={2}>
                <FetchingStatus loading={getDataCurrency.isLoading} />
              </Td>
            </tr>
          ) : (
            <tr>
              <ConversionSection grandTotal={grandTotal} getDataCurrency={getDataCurrency} />
            </tr>
          )}
        </tbody>
      </table>
    </section>
  )
}

function ConversionSection({ getDataCurrency, grandTotal }) {
  // States
  const [dataConversion, setDataConversion] = useState({
    value: getDataCurrency.data[0].value,
    data: getDataCurrency.data[0]
  })

  const handleConversion = (e) => {
    const value = e.target.value
    const data = getDataCurrency.data.find(val => String(val.value) === String(value))

    setDataConversion({ value, data })
  }

  const convertedPrice = data => {
    return grandTotal / 1000 * parseFloat(data.rate)
  }

  const dataConvertedDollar = getDataCurrency.data.find(val => val.label === "USD")

  return (
    <Td colSpan={2}>
      <section className="grow flex md:flex-row flex-col md:justify-between md:items-center gap-x-3">
        <section>
          <div className="font-bold me-2">Currency Converter</div>

          <select
            className="select select-bordered select-sm md:w-[100px] w-full"
            value={dataConversion.value}
            onChange={handleConversion}
          >
            {getDataCurrency.data.map((val, index) => <option key={index} value={val.value}>{val.label}</option>)}
          </select>

          <div>Currency conversion rates are estimates only and subject to change based on your financial institution and payment gateway conversion rates. This is an example only, you will be charged based on the financial gateway provider currency.</div>
        </section>

        <section className="text-right">
          <div className="font-bold">{dataConversion.data.label} {convertedPrice(dataConversion.data).toLocaleString('id-ID')}</div>
          <div className="whitespace-nowrap">(estimate {dataConvertedDollar.label} {convertedPrice(dataConvertedDollar).toLocaleString('id-ID')})</div>
        </section>
      </section>
    </Td>
  )
}

function Gender() {
  // API
  const fetchGenders = useQuery(['dayPasses-genders'], AtributApi.genders)

  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="gender"
      render={({ field, fieldState }) => (
        <Select
          label="Gender"
          placeholder="Select Gender..."
          loading={fetchGenders.isLoading}
          defaultValue={fetchGenders?.data?.find(val => val.value === field.value)}
          options={fetchGenders?.data}
          errors={fieldState.error?.message}
          ref={field.ref}
          onChange={e => field.onChange(e.value)}
        />
      )}
    />
  )
}

function Nationality() {
  // API
  const fetchCountries = useQuery(['dayPasses-countries'], AtributApi.countries)

  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="nationality"
      render={({ field, fieldState }) => (
        <Select
          label="Nationality"
          placeholder="Select Nationality..."
          loading={fetchCountries.isLoading}
          defaultValue={fetchCountries?.data?.find(val => val.value === field.value)}
          options={fetchCountries?.data}
          ref={field.ref}
          onChange={e => field.onChange(e.value)}
          errors={fieldState.error?.message}
        />
      )}
    />
  )
}

function PersonalInsurance() {
  // Form
  const { control, setValue } = useFormContext()
  const ticket = useWatch({
    control,
    name: "ticket"
  })
  
  // Vars
  const ticketPax = ticket.reduce((acc, value) => {
    // Vars
    const complimentary_qty = value.complimentary_qty
    const pax = value.pax
    let total_complimentary_qty = 0

    if (complimentary_qty) total_complimentary_qty = complimentary_qty * pax

    return acc + pax + total_complimentary_qty
  }, 0)

  useEffect(() => {
    setValue("qty_insurance", 0)

    // eslint-disable-next-line
  }, [ticket])

  return (
    <Controller
      control={control}
      name="qty_insurance"
      render={({ field }) => (
        <InputNumber
          {...field}
          ref={null}
          noLabel
          noMargin
          min={0}
          max={ticketPax}
          className="cart-input"
        />
      )}
    />
  )
}

function Code() {
  // API
  const deletePromo = useMutation(value => Api.deletePromo(value))

  // Form
  const { control, getValues, setValue } = useFormContext()
  const _code = useWatch({
    control,
    name: '_code'
  })
  const ticket = useWatch({
    control,
    name: 'ticket'
  })

  // Variables
  const booking_date = getValues("booking_date")

  const InputPromo = ({ onSuccess }) => (
    <Formik
      initialValues={{
        booking_date,
        code: "",
        unique_id: localStorage.getItem('uuid')
      }}
      validationSchema={yup.object().shape({
        code: yup.string().required()
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        Api.promo(values).then(res => {
          resetForm()
          onSuccess(res.promo)
          toast("success", "Success", res.message)
        }).catch(err => {
          toast("error", "Failed", err.response.data.message)
        }).finally(() => {
          setSubmitting(false)
        })
      }}
    >
      {({values, errors, isSubmitting, handleSubmit, handleChange}) => {
        return (
          <Td colSpan={2}>
            <section>
              <div  className="flex md:flex-row flex-col md:justify-between justify-stretch md:items-center items-start">
                <div className="font-bold">Promo Code</div>
  
                <section className="md:w-fit w-full">
                  <section className="flex md:flex-row flex-col gap-3">
                    <input
                      className="grow input input-bordered"
                      name="code"
                      placeholder="Enter Promo Code"
                      value={values.code}
                      onChange={handleChange}
                    />

                    <ActionButton
                      className="bg-black text-white rounded-full"
                      color="ghost"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Enter Code
                    </ActionButton>
                  </section>
  
                  {errors.code && <small className="text-error">{errors.code}</small>}
                </section>
              </div>
            </section>
          </Td>
        )
      }}
    </Formik>
  )

  return (
    <Fragment>
      {_code.map((val, index) => {
          const isFixed = Boolean(val.discount_type === "FIXED")
          const isPercentage = Boolean(val.discount_type === "PERCENTAGE")
          const totalDiscount = ticket.reduce((acc, item) => {
            if (val.promo.sub_type === "GLOBAL" || val.promo.service_available.find(val => val.service_id === item.id)) {
              return acc += isPercentage ? (item.price * (val.amount / 100) * item.pax) : val.amount
            }

            return acc
          }, 0)

          return (
            <tr key={index}>
              <Td>
                <div className="font-bold">Discount</div>
                <small>{isFixed && "Rp. "}{val.amount.toLocaleString('id-ID')}{isPercentage && "%"} off {val.promo_name ? `- ${val.promo_name}` : ""}</small>
              </Td>
              <Td>
                <section className="flex justify-content-end">
                  <TdEnd>IDR {totalDiscount.toLocaleString('id-ID')}</TdEnd>
                  <CloseButton
                    onClick={() => {
                      const data = [..._code]
                      data.splice(index, 1)

                      setValue('_code', data)
                      deletePromo.mutate(val.id)
                    }}
                  />
                </section>
              </Td>
            </tr>
          )
        })}

        {_code.length === 0 && (
          <tr>
            <InputPromo
              onSuccess={data => {
                const value = [..._code]
                value.push(data)

                setValue('_code', value)
              }}
            />
          </tr>
        )}
    </Fragment>
  )
}

function Qty({ index }) {
  // Form
  const { control } = useFormContext()

  return (
    <Controller
      name={`ticket.${index}.pax`}
      control={control}
      render={({ field }) => {
        return (
          <CustomNumberInput
            {...field}
            ref={null}
            noLabel
            noMargin
            min={0}
            className="cart-input"
          />
        )
      }}
    />
  )
}

function TdEnd({ children }) {
  return (
    <div className="font-bold text-end">{children}</div>
  )
}