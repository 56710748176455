// Components
import { DataStatus } from "components"

const FetchingStatus = ({
  loading,
  loadingText = "",
  errorText = "Retrieve data failed"
}) => (
  <DataStatus loading={loading}>{loading ? loadingText : errorText}</DataStatus>
)

export default FetchingStatus