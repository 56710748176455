// Assets
import Image01 from "assets/images/inclusions/image-01.svg"
import Image02 from "assets/images/inclusions/image-02.svg"
import Image03 from "assets/images/inclusions/image-03.svg"
import Image04 from "assets/images/inclusions/image-04.svg"
import Image05 from "assets/images/inclusions/image-05.svg"
import Image06 from "assets/images/inclusions/image-06.svg"
import Image07 from "assets/images/inclusions/image-07.svg"
import Image08 from "assets/images/inclusions/image-08.svg"
import Image09 from "assets/images/inclusions/image-09.svg"
import Image10 from "assets/images/inclusions/image-10.svg"
import Image11 from "assets/images/inclusions/image-11.svg"
import Image12 from "assets/images/inclusions/image-12.svg"

export function PhotoSection() {
  // Variables
  const list = [Image01, Image02, Image03, Image04, Image05, Image06, Image07, Image08, Image09, Image10, Image11, Image12]

  return (
    <section className="grid md:grid-cols-6 grid-cols-4">
      {list.map((item, key) => <img src={item} alt={`splash-${key}`} className="w-full" />)}      
    </section>
  )
}