import service from "service"

const base_url = process.env.REACT_APP_BASE_URL_API
class Api {
  async category(params) {
    const res = await service.get(`${base_url}/list/category`, { params })
    const data = res.data.data

    return data.map(val => {
      return {
        id: val.id,
        name: val.name,
        waiver_variant: JSON.parse(val.waiver_variant ?? '[]')
      }
    })
  }

  async booking(code) {
    const res = await service.get(`${base_url}/booking/walk-in/${code}`)
    return res.data.data
  }

  async questionnaire() {
    const res = await service.get(`${base_url}/questionnaire`)
    return res.data.data
  }
}

export default new Api()