// API
import { useQuery } from "react-query"
import { AtributApi } from "api"

// Components
import { Select } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function Gender() {
  // API
  const fetchGenders = useQuery(['atribut', 'genders'], AtributApi.genders)
  
  // Hooks
  const { control } = useFormContext()

  return (
    <Controller
      name="gender"
      control={control}
      render={method => (
        <Select
          label={<div>Gender <span className="text-error">*</span></div>}
          placeholder="Select Gender..."
          loading={fetchGenders.isLoading}
          defaultValue={fetchGenders?.data?.find(val => val.value === method.field.value)}
          options={fetchGenders?.data}
          errors={method.formState.errors?.gender?.message}
          onChange={e => method.field.onChange(e.value)}
        />
      )}
    />
  )
}