// React
import { Fragment } from "react"

// API
import { useQuery } from "react-query"
import { HomeApi } from "./api"

// Assets
import 'assets/css/style.css'

// Components
import { ActionButton } from "components"
import { Banner, CompanySection, ServiceItem } from "./components"

// Configs
import { ultimate } from "Config"

// Third-Party Library
import { Link } from "react-router-dom"

export default function Home ({ baseData }) {
  return (
    <Fragment>
      <Banner />

      <section className="my-16 container max-w-5xl grid md:grid-cols-2 items-center gap-3">
        <div className="text-4xl font-bold">Discover endless possibilities with FINNS Bali Day Passes!</div>

        <section className="flex flex-col gap-3">
          <div>FINNS Bali Day Passes, are your way to get the best value on all FINNS Recreation Club's amazing facilities including; tennis, water parks, bowling alley's, kids clubs, fitness centres, recovery centres, day spas & more.</div>
          <div className="text-xl font-bold">Purchase your pass online now to save big.</div>
        </section>
      </section>

      <section className="container max-w-5xl flex flex-col items-center gap-5 my-10">
        <section className="flex flex-wrap justify-center gap-3">
          <SaleServices />

          {baseData.category.map((item, key) => {
            // Vars
            const image = `${process.env.REACT_APP_BASE_URL}${item.image}`
            const image_slider = item.image_slider ?? []
            const price = item.online_service.reduce((acc, item, key) => {
              if (key === 0) {
                return item.price
              }

              if (acc > item.price) {
                return item.price
              }

              return acc
            }, 0)

            return (
              <ServiceItem
                key={key}
                best_for={item.best_for}
                detail={item.detail}
                image={image}
                image_slider={image_slider}
                name={item.name}
                price={price}
                slug={item.slug}
              />
            )
          })}
        </section>
      </section>

      <section className="my-20">
        <CompanySection />
      </section>

      <div
        className="w-full h-[444px] bg-gold bg-no-repeat bg-cover bg-center text-white flex flex-col justify-center items-center gap-5 text-center"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ultimate})` }}
      >
        <section className="mx-auto max-w-[600px]">
          <div className="font-bold text-2xl mb-3">Ultimate Experience Super Fun Pass Voucher Redemption</div>
          <div>Already have a Super Fun Pass voucher from your Ultimate Experience at FINNS Beach Club? Hit the link below to redeem your free pass using the unique code provided via email</div>
        </section>

        <Link to="/misc/ultimate-experience" className="md:w-fit w-full">
          <ActionButton color="ghost" className="w-full whitespace-nowrap px-8 bg-gold rounded-full">Redeem Now</ActionButton>
        </Link>
      </div>
    </Fragment>
  )
}

function SaleServices() {
  // API
  const fetchList = useQuery(['list', 'promo'], HomeApi.promo_list)

  if (fetchList.data?.length > 0) {
    return (
      <Fragment>
        {fetchList.data.map((item, key) => (
          <ServiceItem
            is_sale
            key={key}
            best_for={item.description}
            detail={item.notes}
            image={item.image}
            name={item.name}
            ticket={item.ticket}
            slug="flash-sale"
            price={item.price}
            valid_date={item.valid_date}
          />
        ))}
      </Fragment>
    )
  }

  return <Fragment />
}