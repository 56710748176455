// React
import { Fragment, memo, useContext, useEffect } from "react"

// API
import { useMutation, useQuery } from "react-query"
import service from "service"
import { SettingApi } from "api"
import { Api as CategoryApi } from "../../api"
import { Api } from "../OrderReview/api"

// Components
import { ActionButton, DataStatus, FullCalendar, Loading, Section } from "components"
import { OrderReview } from "../OrderReview"

// Configs
import { envList } from "configs"

// Contexts
import { DataContext, ValidDateContext } from "../../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Functions
import { dateConvert, sweetAlert, toast } from "functions"

// Icons
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"
import { TbCalendarMinus, TbChevronLeft, TbChevronRight, TbCurrencyDollar, TbRotateClockwise } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"
import useToggle from "@react-hook/toggle"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { isFlashSale } from "../../utils"

export const FormSection = ({
  baseData,
  min_date,
  setting
}) => {
  // Hooks
  const { value } = useParams()

  // Functions
  const is_flash_sale = isFlashSale(value)

  // Form
  const { control, getValues, setValue } = useFormContext()
  const booking_date = useWatch({
    control,
    name: "booking_date"
  })
  const page = useWatch({
    control,
    name: "page"
  })

  // API
  const category = useQuery(
    [value, booking_date],
    () => CategoryApi.categories(is_flash_sale ? envList.flash_sale.to : value, {
      booking_date,
      isPromo: is_flash_sale ? Boolean(is_flash_sale) : undefined
    }),
    {
      onSuccess: res => {
        setValue("ticket", res.online_service.map((item) => {
          return {
            ...item,
            pax: 0
          }
        }))
      },
    }
  )
  const checkAvailable = useMutation(
    value => service.post(`${process.env.REACT_APP_BASE_URL_API}/check-available`, {
      ...value,
      ticket: getValues("ticket")
    }),
    {
      onSuccess: () => setValue("is_date_available", true),
      onError: () => setValue("is_date_available", false)
    }
  )

  useEffect(() => window.scrollTo(0, 0), [page])

  return (
    <Fragment>
      <Section className="flex justify-center text-center mb-3">
        <section className="rounded-full shadow-lg my-1">
          <NavItemData pageNumber={1}>
            <div className="flex items-center">
              <TbCalendarMinus size={16} />
              <div className="md:block hidden">
                <span className='ml-1'>ARRIVAL DATE</span>
              </div>
            </div>
          </NavItemData>

          <NavItemData pageNumber={2}>
            <div className="flex items-center">
              <TbCurrencyDollar size={16} />
              <div className="md:block hidden">
                <span className='ml-1'>CHECKOUT</span>
              </div>
            </div>
          </NavItemData>
        </section>
      </Section>

      {category.isLoading || category.isRefetching || category.isError ? (
        <DataStatus>No data available</DataStatus>
      ) : (
        <Section>
          {page === 1 && (
            <CalendarSection
              checkAvailable={checkAvailable}
              is_flash_sale={is_flash_sale}
              min_date={min_date}
            />
          )}

          {page === 2 && (
            <OrderReview
              setting={setting}
              waiver_type={category.data.waiver_type}
              is_departure={Boolean(category.data.is_departure)}
              checkAvailable={checkAvailable}
            />
          )}
        </Section>
      )}

      <section>
        <Terms baseData={baseData} />
      </section>

      <section>
        <div className="fixed bottom-0 left-0 right-0 text-white z-10">
          <SummarySection setting={setting} />
        </div>
      </section>

      <CodeChecker />
    </Fragment>
  )
}

function CalendarSection({ checkAvailable, is_flash_sale, min_date }) {
  // Hooks
  const valid_date = useContext(ValidDateContext)
  const { isValid } = useContext(DataContext)
  const { value } = useParams()

  // Form
  const { control, getValues, setValue } = useFormContext()

  // API
  const fetchDate = useQuery(["day-pass", "calendar", value], () => CategoryApi.blackout_date({ slug: value }))

  // Vars
  let validDate = undefined

  if (isValid) {
    validDate = new Date(process.env.REACT_APP_VALID_DATE)
  } else if (is_flash_sale && valid_date) {
    validDate = new Date(valid_date)
  }

  if (fetchDate.isLoading || checkAvailable.isLoading) {
    return (
      <div style={{ height: "50vh" }}>
        <div className="h-100 flex justify-content-center items-center">
          <Loading color="primary" />
        </div>
      </div>
    )
  } else {
    return (
      <Controller
        control={control}
        name="booking_date"
        render={({ field }) => {
          return (
            <FullCalendar
              value={field.value}
              events={fetchDate.data ?? []}
              maxDate={validDate}
              onClick={(val) => {
                if (moment(val).isBefore(moment(min_date))) {
                  return toast("error", "Date is not valid for this product")
                }

                sweetAlert({
                  title: "",
                  html: `<div>Please confirm you would like to select <br /><strong>${dateConvert(val).withDay.detail}?</strong></div>`,
                  icon: null,
                  confirmButtonText: "Yes - I'd like this date",
                  cancelButtonText: "Change"
                }).then(res => {
                  // Variables
                  const value = dateConvert(val).default.format

                  if (res.isConfirmed) {
                    if (getValues("ticket").length === 0) {
                      toast("error", "Error", "Please select minimum 1 Ticket")
                    } else {
                      checkAvailable.mutateAsync({ booking_date: value }).then(() => {
                        field.onChange(value)
                        setValue("page", 2)
                      }).catch(err => {
                        let errorMessage = ""

                        if (err.response.data) {
                          errorMessage = err.response.data?.message !== "" ? err.response.data.message : "Date is not available"
                        } else {
                          errorMessage = err.response.statusText
                        }

                        sweetAlert({
                          confirmButtonText: "Confirm",
                          icon: "info",
                          noCancelButton: true,
                          title: "For your information",
                          html: `<div>${errorMessage}</div>`,
                        })
                      })
                    }
                  }
                })
              }}
            />
          )
        }}
      />
    )
  }
}

function CodeChecker() {
  // Form
  const { control, setValue } = useFormContext()
  const ticket = useWatch({
    control,
    name: "ticket"
  })
  const _code = useWatch({
    control,
    name: "_code"
  })

  useEffect(() => {
    if (_code.length > 0) {
      const discount = ticket.reduce((acc, item) => {
        if (_code[0].promo.sub_type === "GLOBAL" || _code[0].promo.service_available.find(code => code.service_id === item.id)) {
          return acc += _code[0].discount_type === "PERCENTAGE" ? (item.price * (_code[0].amount / 100) * item.pax) : _code[0].amount
        }
    
        return acc
      }, 0)

      if (discount === 0) {
        Api.deletePromo(_code[0].id).finally(() => {
          toast("error", "Error", "The promo is not available for this packages, promo has been deleted")
          setValue("_code", [])
        })
      }
    }

    // eslint-disable-next-line
  }, [_code, ticket])

  return null
}

function NavItemData({
  pageNumber = 1,
  children,
  onClick
}) {
  // Form
  const { control, setValue } = useFormContext()
  const page = useWatch({
    control,
    name: "page"
  })

  return (
    <ActionButton
      color={`${page >= pageNumber ? "primary" : "ghost"}`}
      className={`rounded-full px-10 btn-sm ${page >= pageNumber ? "text-white" : "text-primary"}`}
      onClick={() => onClick ? onClick() : setValue("page", pageNumber)}
      style={{ marginLeft: pageNumber !== 1 && "-35px" }}
    >
      {children}
    </ActionButton>
  )
}

function SummarySection({ setting }) {
  // Hooks
  const navigate = useNavigate()
  const { start_page } = useContext(DataContext)

  // API
  const fetchInsurance = useQuery(['setting', 'personal_insurance'], SettingApi.personal_insurance)

  // Form
  const { control, formState, handleSubmit, setValue } = useFormContext()
  const _code = useWatch({
    control,
    name: "_code"
  })
  const page = useWatch({
    control,
    name: "page"
  })
  const qty_insurance = useWatch({
    control,
    name: "qty_insurance"
  })
  const ticket = useWatch({
    control,
    name: "ticket"
  })
  const is_date_available = useWatch({
    control,
    name: "is_date_available"
  })
  const onSubmit = value => {
    return new Promise((resolve) => {
      // Vars
      const isTicketAvailable = Boolean(value.ticket.find(item => item.pax > 0))

      if (!isTicketAvailable) {
        toast("error", "Error", "No tickets selected")
        return resolve()
      }

      Api.store({
        ...value,
        ticket: value.ticket.filter(item => item.pax > 0),
        walk_in: false
      }).then(res => {
        const data = res.data
  
        localStorage.removeItem('uuid')
        if (value.walk_in || data.redirect) {
          navigate(`/success?unique_id=${data.link}`, { replace: true })
        } else {
          window.location.href = data.link
        }
      }).catch(err => {
        toast("error", "Error", err.response.data.message)
      }).finally(resolve)
    })
  }
  
  // Vars
  const ticketIsEmpty = !ticket.find(item => item.pax > 0)

  // Variables
  const totalPrice = ticket.reduce(
    (previousValue, { pax, price }) => previousValue + (pax * price), 0
  )
  const totalDiscount = () => {
    let discount = 0

    if (_code.length === 0) {
      discount = 0
    } else {
      discount = ticket.reduce((acc, item) => {
        if (_code[0].promo.sub_type === "GLOBAL" || _code[0].promo.service_available.find(code => code.service_id === item.id)) {
          return acc += _code[0].discount_type === "PERCENTAGE" ? (item.price * (_code[0].amount / 100) * item.pax) : _code[0].amount
        }
    
        return acc
      }, 0)
    }

    return totalPrice - discount
  }
  const tax = totalDiscount() * setting.tax_services
  const insurance_price = qty_insurance * (fetchInsurance.data ?? 0)
  const grandTotal = totalDiscount() + tax + insurance_price

  if (page === 1) {
    return (
      <div className="bg-primary flex justify-between items-center py-1 px-3">
        <div className="flex-fill">
          <button
            className="btn rounded-full bg-white hover:bg-transparent hover:border-white text-primary hover:text-white"
            onClick={() => {
              if (page === start_page) {
                navigate('/')
              } else {
                setValue("page", page - 1)
              }
            }}
          >
            <TbChevronLeft size={16} /> <span className="md:block hidden">Previous</span>
          </button>
        </div>
  
        <div className="flex-fill">
          {page > start_page && (
            <button
              className="btn rounded-full bg-black text-white"
              onClick={() => {
                sweetAlert({
                  title: "",
                  html: `<div>Would you like to start over?</div>`,
                  icon: null,
                  confirmButtonText: "Okay",
                  cancelButtonText: "Cancel"
                }).then(res => {
                  if (res.isConfirmed) {
                    setValue("page", start_page)
                    setValue("ticket", ticket.map(item => {
                      return {
                        ...item,
                        qty: 0
                      }
                    }))
                  }
                })
              }}
            >
              <TbRotateClockwise size={16} /> <span className="md:block hidden">Start Over</span>
            </button>
          )}
        </div>
  
        <div className="text-end flex-fill">
          <h4>Your Booking Total</h4>
          <h3 className="m-0">IDR {grandTotal.toLocaleString()}</h3>
        </div>
  
        <div className="flex-fill text-end">
          {page < 2 && (
            <button
              className="btn rounded-full bg-white hover:bg-transparent hover:border-white text-primary hover:text-white"
              onClick={() => setValue("page", page + 1)}
            >
              <span className="md:block hidden">Next</span> <TbChevronRight size={16} />
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white text-black shadow-inner">
      <section className="container flex flex-col gap-3 text-xl py-3">
        <div className="font-bold">Total Cart</div>

        <section className="flex justify-between">
          <div>Total</div>
          <div className="font-bold">IDR {grandTotal.toLocaleString()}</div>
        </section>

        <ActionButton
          type="button"
          color="ghost"
          className="bg-black rounded-full text-white"
          loading={formState.isSubmitting ? "true" : undefined}
          disabled={!is_date_available || ticketIsEmpty}
          onClick={handleSubmit(onSubmit)}
        >
          Checkout
        </ActionButton>
      </section>
    </div>
  )
}

const Terms = memo(function Terms({ baseData }) {
  // Hooks
  const [showTerms, toggleTerms] = useToggle(false, true)

  return (
    <Fragment>
      <div className="w-full bg-primary rounded-none no-animation flex flex-col items-center py-2 select-none cursor-pointer" onClick={toggleTerms}>
        <div>Terms & Conditions</div>
        <div>{showTerms ? <FaChevronCircleUp /> : <FaChevronCircleDown />}</div>
      </div>

      <Section>
        {showTerms && <div className="service-detail" dangerouslySetInnerHTML={{ __html: baseData.tc }} />}
      </Section>
    </Fragment>
  )
})