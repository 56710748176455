// React
import { useRef } from "react"

// API
import { useQuery } from "react-query"
import { AtributApi } from "api"
import { Api as OrderApi } from "../api"
import { Api } from "./api"

// Components
import { ActionButton, Checkbox, DeleteButton, Input, Select, Signature, Terms } from "components"

// Form
import * as yup from "yup"
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"

// Functions
import { dateConvert, toast, validation } from "functions"
import { cardStyle } from "../functions"

// Third-Party Libraries
import moment from "moment"
import uuid from "react-uuid"
import { useNavigate } from "react-router-dom"

export default function UltimateExperience() {
  // Hooks
  const navigate = useNavigate()
  const signatureRef = useRef(null)

  // Form
  const defaultValues = {
    booking_date: dateConvert(new Date()).default.format,
    policy: false,
    terms: false,
    email: "",
    first_name: "",
    gender: "",
    last_name: "",
    nationality: 101,
    phone: "",
    qty_insurance: 0,
    waiver: false,
    ticket: []
  }
  const validationSchema = yup.object().shape({
    terms: yup.boolean().label("Terms and Conditions").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
    policy: yup.boolean().label("Policy").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
    first_name: yup.string().label("First Name").required(),
    last_name: yup.string().label("Last Name").required(),
    phone: yup.string().label("Phone").required(),
    zip: yup.string().label("Zip/Post Code").required(),
    nationality: yup.string().label("Nationality").required(),
    email: yup.string().label("Email").email(validation().email.label).required(),
    gender: yup.string().label("Gender").required(),
    waiver: yup.boolean().label("Waiver").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
    ticket: yup.array().label("Code").required().min(1),
  })
  const onSubmit = values => {
    return new Promise((resolve) => {
      if (signatureRef.current?.isEmpty()) {
        toast("error", "Failed", "Signature is empty")
        return resolve()
      }

      OrderApi.store({
        ...values,
        additional_cost: false,
        signature: signatureRef.current?.toDataURL('image/png'),
        ultimate: true,
        unique_id: uuid()
      }).then(res => {
        const data = res.data
  
        navigate(`/success?unique_id=${data.link}`, {
          replace: true,
          state: { barcode: data.barcode }
        })
      }).catch(err => {
        toast("error", "Error", err.response.statusText)
      }).finally(resolve)
    })
  }
  const { control, formState, handleSubmit } = useForm({ defaultValues, resolver: yupResolver(validationSchema) })
  const { fields, append, remove } = useFieldArray({ control, name: "ticket" })

  return (
    <div className="container mt-3">
      <h4 className="text-center font-bold">ULTIMATE EXPERIENCE</h4>
      <h5 className="text-center font-bold">Reservation date: {moment(new Date()).format("dddd, D MMMM YYYY")}</h5>

      <FormProvider control={control}>
        <section>
          <section className="card border rounded-3 shadow-lg" style={cardStyle}>
            <section className="card-body">
              <InputCode
                onAddCode={value => {
                  if (fields.find(item => item.code === value[0].code)) {
                    return toast("error", "Code already exists")
                  }

                  append(value)
                }}
              />

              <section>
                <label className="m-0 font-bold">Code List</label>

                <section className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr className="text-center">
                        <th>Code</th>
                        <th>Product/Item</th>
                        <th>Type</th>
                        <th style={{ width: 10 }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {fields.length === 0 ? (
                        <tr>
                          <td colSpan={5} className="text-center">No data available</td>
                        </tr>
                      ) : fields.map((item, key) => {
                        // Variables
                        const isUnique = Boolean(item.code !== fields[key - 1]?.code ?? "")
                        const sameField = fields.filter(i => i.code === item.code)

                        return (
                          <tr key={key}>
                            {isUnique && <td rowSpan={sameField.length}>{item.code}</td>}
                            <td>{item.name}</td>

                            <td>
                              <Controller
                                control={control}
                                name={`ticket.${key}.type`}
                                render={({ field }) => (
                                  <section className="flex gap-3">
                                    <Checkbox
                                      value={field.value === "adult"}
                                      onChange={() => field.onChange("adult")}
                                    >
                                      Adult
                                    </Checkbox>

                                    <Checkbox
                                      value={field.value === "child"}
                                      onChange={() => field.onChange("child")}
                                    >
                                      Child
                                    </Checkbox>
                                  </section>
                                )}
                              />
                            </td>

                            {isUnique && (
                              <td className="text-center" rowSpan={sameField.length}>
                                <DeleteButton onClick={() => remove(sameField.map((_, key) => key))} />
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </section>

                <ErrorMessage
                  errors={formState.errors}
                  name="ticket"
                  render={({ message }) => <small className="text-error">{message}</small>}
                />
              </section>
            </section>
          </section>
        </section>

        <section className="my-3">
          <section className="card border rounded-3 shadow-lg" style={cardStyle}>
            <section className="card-body">
              <div className="container-md">
                <div className="mb-2">
                  <h5 className="font-bold">Guest Details</h5>

                  <section className="grid md:grid-cols-4 gap-3">
                    <section className="col-span-2">
                      <Controller
                        name="first_name"
                        control={control}
                        render={method => (
                          <Input
                            {...method.field}
                            label={<div>First Name <span className="text-error">*</span></div>}
                            ref={null}
                            errors={method.formState.errors?.first_name?.message}
                          />
                        )}
                      />
                    </section>

                    <section className="col-span-2">
                      <Controller
                        name="last_name"
                        control={control}
                        render={method => (
                          <Input
                            {...method.field}
                            label={<div>Last Name <span className="text-error">*</span></div>}
                            ref={null}
                            errors={method.formState.errors?.last_name?.message}
                          />
                        )}
                      />
                    </section>

                    <section className="col-span-2">
                      <Nationality />
                    </section>

                    <section>
                      <Controller
                        name="phone"
                        control={control}
                        render={method => (
                          <Input
                            {...method.field}
                            label={<div>Phone <span className="text-error">*</span></div>}
                            ref={null}
                            errors={method.formState.errors?.phone?.message}
                          />
                        )}
                      />
                    </section>

                    <section>
                      <Controller
                        name="zip"
                        control={control}
                        render={method => (
                          <Input
                            {...method.field}
                            label={<div>Zip/Post Code <span className="text-error">*</span></div>}
                            ref={null}
                            errors={method.formState.errors?.zip?.message}
                          />
                        )}
                      />
                    </section>

                    <section className="col-span-2">
                      <Gender />
                    </section>

                    <section className="col-span-2">
                      <Controller
                        name="email"
                        control={control}
                        render={method => (
                          <Input
                            {...method.field}
                            label={<div>Email <span className="text-error">*</span></div>}
                            ref={null}
                            errors={method.formState.errors?.email?.message}
                          />
                        )}
                      />
                    </section>
                  </section>

                  <section className="my-3">
                    <Controller
                      name="terms"
                      control={control}
                      render={method => (
                        <Checkbox
                          onChange={() => method.field.onChange(!method.field.value)}
                          value={method.field.value}
                          errors={method.formState.errors?.terms?.message}
                        >
                          I accept all FINNS Recreation Club & FINNS Beach Club Booking & venue <Terms.Condition /> ***
                        </Checkbox>
                      )}
                    />

                    <Controller
                      name="policy"
                      control={control}
                      render={method => (
                        <Checkbox
                          onChange={() => method.field.onChange(!method.field.value)}
                          value={method.field.value}
                          errors={method.formState.errors?.policy?.message}
                        >
                          I understand and accept the FINNS Recreation Club & FINNS Beach Club Booking face & head tattoo <Terms.Policy /> ***
                        </Checkbox>
                      )}
                    />

                    <Controller
                      name="waiver"
                      control={control}
                      render={method => (
                        <Checkbox
                          onChange={() => method.field.onChange(!method.field.value)}
                          value={method.field.value}
                          errors={method.formState.errors?.waiver?.message}
                        >
                          I agree to the <Terms.SplashWaiver />
                        </Checkbox>
                      )}
                    />
                  </section>

                  <Signature ref={signatureRef} />

                  <div className="text-center mt-2">
                    <ActionButton
                      loading={formState.isSubmitting}
                      color="primary"
                      className="text-white"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Book Now
                    </ActionButton>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </FormProvider>
    </div>
  )
}

function Gender() {
  // API
  const fetchGenders = useQuery(['atribut', 'genders'], AtributApi.genders)
  
  // Hooks
  const { control } = useFormContext()

  return (
    <Controller
      name="gender"
      control={control}
      render={method => (
        <Select
          label={<div>Gender <span className="text-error">*</span></div>}
          placeholder="Select Gender..."
          loading={fetchGenders.isLoading}
          defaultValue={fetchGenders?.data?.find(val => val.value === method.field.value)}
          options={fetchGenders?.data}
          errors={method.formState.errors?.gender?.message}
          onChange={e => method.field.onChange(e.value)}
        />
      )}
    />
  )
}

function InputCode({ onAddCode }) {
  // Form
  const defaultValues = { code: "" }
  const validationSchema = yup.object().shape({
    code: yup.string().label("Code").required()
  })
  const { control, formState, handleSubmit, reset } = useForm({ defaultValues, resolver: yupResolver(validationSchema) })
  const onSubmit = values => {
    return new Promise((resolve) => {
      Api.check_code(values).then(res => {
        reset()
        onAddCode([...Array(res.max_used - res.used)].map(() => {
          return {
            code: values.code,
            name: res.item,
            type: "adult"
          }
        }))
      }).catch(err => {
        toast("error", err.response.data.message)
      }).finally(resolve)
    })
  }

  return (
    <div className="mb-3">
      <label className="font-bold m-0">Code</label>

      <section className="join w-full">
        <Controller
          name="code"
          control={control}
          render={method => <input {...method.field} className="input input-bordered grow join-item min-w-[0px] w-[1%]" />}
        />

        <ActionButton type="button" className="join-item" loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>Submit</ActionButton>
      </section>

      <ErrorMessage
        errors={formState.errors}
        name="code"
        render={({ message }) => <small className="text-error">{message}</small>}
      />
    </div>
  )
}

function Nationality() {
  // API
  const fetchCountries = useQuery(['atribut', 'countries'], AtributApi.countries)

  // Hooks
  const { control } = useFormContext()

  return (
    <Controller
      name="nationality"
      control={control}
      render={method => (
        <Select
          label={<div>Nationality <span className="text-error">*</span></div>}
          placeholder="Select Nationality..."
          loading={fetchCountries.isLoading}
          defaultValue={fetchCountries?.data?.find(val => val.value === method.field.value)}
          options={fetchCountries?.data}
          onChange={e => method.field.onChange(e.value)}
          errors={method.formState.errors?.nationality?.message}
        />
      )}
    />
  )
}