// React
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from "./reportWebVitals"

// App
import App from './App'

// Third-Party Library
import { QueryClient, QueryClientProvider } from 'react-query'

// React Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ToastContainer newestOnTop />
    </QueryClientProvider>
  </React.StrictMode>
)

reportWebVitals()