// React
import { useEffect } from "react"

// Components
import { ActionButton, Input, Navbar, SplashStatus } from "components"

// Third-Party Library
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

// API
import { useQueries } from "react-query"
import { Api } from "./api"
import { SettingApi } from "api"

// Config
import menu from "navigations/dayPassesNav"
import { logo } from "Config"

// Form
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Functions
import { toast } from "functions"

const DayPassesRouter = ({ baseLink }) => {
  // Hooks
  const { pathname } = useLocation()

  // API
  const fetchData = useQueries([
    { queryKey: ['dayPasses', 'router'], queryFn: () => Api.companies() },
    { queryKey: ['setting', 'tax_services'], queryFn: SettingApi.tax_services }
  ])

  useEffect(() => window.scrollTo(0, 0), [pathname])

  if (fetchData[0].isLoading || fetchData[0].isError) {
    return (
      <SplashStatus loading={fetchData[0].isLoading} />
    )
  } else if (fetchData[1].isLoading || fetchData[1].isError) {
    return (
      <SplashStatus loading={fetchData[1].isLoading} />
    )
  } else {
    // Variables
    const data = fetchData[0].data

    return (
      <div className="min-vh-100 flex flex-col">
        {/* Navbar */}
        <Navbar
          sticky="top"
          logo={logo}
          baseLink={baseLink}
          menu={menu}
          noColorChange
        />

        {/* Banner */}
        {/* <Banner fetchData={fetchData} /> */}

        {/* Routes */}
        <section>
          <Routes>
            {menu.map((val, index) => {
              return (
                <Route
                  key={index}
                  path={val.link}
                  element={(
                    <val.component
                      baseData={data}
                      setting={{
                        tax_services: fetchData[1].data
                      }} 
                    />
                  )}
                />
              )
            })}

            <Route path="*" element={<Navigate to="/error/404" replace />} />
          </Routes>
        </section>

        {/* Footer */}
        <footer
          style={{
            backgroundColor: '#333333',
            backgroundSize: 'cover',
            paddingBottom: '5rem'
          }}
          className="mt-auto pt-5"
        >
          <div className="container grid md:grid-cols-2 gap-3">
            <section>
              <h5 className="text-white mb-3">ADDRESS</h5>

              <InfoItem label="FINNS RECREATION CLUB">{data.address}</InfoItem>
              <InfoItem label="Email">
                <a className="text-primary" href="mailto:info@finnsrecclub.com">info@finnsrecclub.com</a>
              </InfoItem>
              <InfoItem label="Phone">
                <a className="text-primary" href="tel:+62%20(361)%20848%203939">+62 (361) 848 3939</a>
              </InfoItem>
              <InfoItem label="WhatsApp (Text Only)">
                <a className="text-primary" href="https://api.whatsapp.com/send?phone=628113882015">+62 811 388 2015</a>
              </InfoItem>
            </section>

            <section>
              <h5 className="text-white mb-3">NEWSLETTER SIGNUP</h5>
              <FormSection />
            </section>
          </div>
        </footer>
      </div>
    )
  }
}

function FormSection() {
  // Form
  const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  }
  const validationSchema = yup.object().shape({
    first_name: yup.string().label("First Name").required(),
    last_name: yup.string().label("Last Name").required(),
    email: yup.string().email().label("Email").required(),
    phone: yup.string().label("Phone")
  })
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = value => {
    return new Promise((resolve) => {
      Api.active_campaign(value).then(res => {
        reset()
        toast("success", "Subscribe Success")
      }).catch(err => {
        toast("error", err.response.data.message)
      }).finally(resolve)
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      <Controller
        control={control}
        name="first_name"
        render={({ field, fieldState }) => <Input {...field} noLabel ref={null} placeholder="First Name" errors={fieldState.error?.message} />}
      />

      <Controller
        control={control}
        name="last_name"
        render={({ field, fieldState }) => <Input {...field} noLabel ref={null} placeholder="Last Name" errors={fieldState.error?.message} />}
      />

      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => <Input {...field} noLabel type="email" ref={null} placeholder="Email" errors={fieldState.error?.message} />}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field, fieldState }) => <Input {...field} noLabel ref={null} placeholder="Phone" errors={fieldState.error?.message} />}
      />

      <ActionButton block loading={formState.isSubmitting}>SUBSCRIBE NOW</ActionButton>
    </form>
  )
}

function InfoItem({ label = "", children }) {
  return (
    <section className="mb-3">
      <div className="text-primary font-bold">{label}</div>
      <div className="text-white">{children}</div>
    </section>
  )
}

export default DayPassesRouter