const validation = (value, misc) => {
  return {
    required: {
      label: "Field is required"
    },
    email: {
      label: "Field must be a valid email address"
    },
    min: {
      value,
      label: `Field must be at least ${value} ${misc ?? ""}`
    },
    typeError: {
      label: "Wrong field format"
    },
    exact: {
      label: `${Array.isArray(value) ? value[0] : "-"} must match with ${Array.isArray(value) ? value[1] : "-"}`
    },
    number: {
      value: /^[0-9]*$/,
      label: "Field must be a number"
    },
    mustAccept: {
      value: [true],
      label: "Field must be accepted."
    }
  }
}

export default validation