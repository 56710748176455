// React
import { Fragment } from "react"

// Component
import { Dialog, Transition } from "@headlessui/react"

// Icon
import { FiX } from "react-icons/fi"

// Third-Party Library
import PropTypes from "prop-types"

const Modal = props => {
  const size = {
    md: "max-w-md",
    lg: "max-w-4xl"
  }

  return (
    <Transition appear show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (props.onClose) {
            props.onClose()
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`${size[props.size ?? "md"]} w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${props.className}`}>
                {props.children}

                {props.showClose && (
                  <div
                    className="absolute top-5 right-5 cursor-pointer"
                    onClick={() => {
                      if (props.onClose) {
                        props.onClose()
                      }
                    }}
                  >
                    <FiX className="w-5 h-5 text-error" />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  showClose: PropTypes.bool,
  size: PropTypes.string
}

const Header = props => (
  <Dialog.Title as="h3" className={`text-lg font-medium leading-6 text-gray-900 ${props.className}`}>
    {props.children}
  </Dialog.Title>
)
Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

const Body = props => (
  <div className={`pt-4 ${props.className}`}>{props.children}</div>
)
Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

const Footer = props => (
  <div className={`pt-4 border-t ${props.className}`}>{props.children}</div>
)
Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export { Modal }