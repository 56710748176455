import service from "service"

const base_url = `${process.env.REACT_APP_BASE_URL_API}/booking`
class Api {
  store(data) {
    return service.post(base_url, data)
  }

  async promo(data) {
    const res = await service.post(`${base_url}/promo`, data)
    const value = res.data

    return {
      ...value,
      promo: {
        ...value.promo,
        amount: parseInt(value.promo?.amount ?? 0)
      }
    }
  }

  async getPromo(data) {
    const res = await service.post(`${base_url}/get/promo`, data)
    const value = res.data

    return {
      ...value,
      promo: value.promo.map(val => {
        return {
          ...val,
          amount: parseInt(val?.amount ?? 0)
        }
      })
    }
  }

  deletePromo(data) {
    return service.delete(`${base_url}/promo/${data}`)
  }
}

export default new Api()