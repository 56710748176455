const mappingData = (data, label = 'name', value = 'id') => {
  return data.map(val => {
    return {
      ...val,
      value: val[value],
      label: val[label]
    }
  })
}

export default mappingData