// Components
import { ActionButton } from "components/buttons"
import { Loading } from "components/feedbacks"

// Configs
import { logo } from "Config"
import { useNavigate } from "react-router-dom"

// Icons
import { FaChevronCircleLeft } from "react-icons/fa"

const SplashStatus = ({ loading }) => {
  // Hooks
  const navigate = useNavigate()

  return (
    <div className="position-relative" style={{ height: '100vh' }}>
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <img alt="Logo" src={logo} height={120} /><br />
        {loading ? (
          <Loading color="primary" />
        ) : (
          <div className="text-center">
            <div>Fetch Data Error</div>
            <ActionButton color="primary" onClick={() => navigate(-1)} className="text-white">
              <FaChevronCircleLeft /> Go Back
            </ActionButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default SplashStatus