// React
import { Fragment, forwardRef } from "react"

// Third-Party Libraries
import ReactSignatureCanvas from "react-signature-canvas"

// Icons
import { TbRotate2 } from "react-icons/tb"

export const Signature = forwardRef((_, ref) => {
  return (
    <Fragment>
      <ReactSignatureCanvas
        ref={ref}
        canvasProps={{
          width: 300,
          height: 150,
          style: {
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: "rgb(221, 221, 221)"
          }
        }}
      />
  
      <TbRotate2 title="Reset" size={30} onClick={() => ref.current?.clear()} style={{ cursor: "pointer" }} />
    </Fragment>
  )
})