// React
import { useEffect, useState } from "react"

// API
import service from "service"

export function useCategory(slug) {
  // Hooks
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    service.get(`${process.env.REACT_APP_BASE_URL_API}/categories/slug/${slug}`).then(res => {
      // Variables
      const data = res.data.data

      setData({
        ...data,
        details: data.details.map(item => {
          return {
            ...item,
            company: {
              ...item.company,
              background: `${process.env.REACT_APP_BASE_URL}${item.company.background}`,
              logo: `${process.env.REACT_APP_BASE_URL}${item.company.logo}`
            }
          }
        }),
        image2: `${process.env.REACT_APP_BASE_URL}${data.image2}`,
        menu: data.menu?.map(item => {
          return {
            ...item,
            image: `${process.env.REACT_APP_BASE_URL}/${item.image}`
          }
        }) ?? [],
        online_service: data.online_service.map(item => {
          return {
            ...item,
            price: parseInt(item.price ?? "0"),
          }
        })
      })
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}