export function getCategoryProps(slug) {
  const category_list = {
    splash: {
      hasPhoto: true,
      hasTwoWords: true,
      hideTitle: true,
      title: ["FINNS", "SPLASH", "DAY PASS"],
      list_style: "service-detail-splash",
      menu: [
        {
          title: "Slides",
          description: "Experience the ultimate Water Park adventure at Splash Water Park with various Slides"
        },
        {
          title: "Family Fun",
          description: "#1 family-friendly Water Park. It's a perfect Bali fun day out for the whole family."
        },
        {
          title: "Daybeds & Cabanas",
          description: "Each Splash Water Park ticket comes with access to our single beds free of charge. First in - first served."
        }
      ],
      color: {
        text: "text-[#00AEB7]",
        background: "text-white bg-[#00AEB7]",
        background_light: "bg-[#00AEB71A]"
      }
    },
    superfunpass: {
      hasCompany: true,
      hideTitle: true,
      title: ["FINNS", "SUPER FUN", "PASS"],
      list_style: "service-detail-superfunpass",
      menu: [],
      color: {
        text: "text-[#E84949]",
        background: "text-white bg-[#E84949]",
        background_light: "bg-[#FFF4F4]"
      }
    },
    finnsbalidaypass: {
      hasCompany: true,
      title: ["FINNS", "DAY", "PASS"],
      list_style: "service-detail-finnsbalidaypass",
      menu: [],
      color: {
        text: "text-[#77C7CB]",
        background: "text-white bg-[#77C7CB]",
        background_light: "bg-[#77C7CB1A]"
      }
    },
    departurepass: {
      hasCompany: true,
      hasLongTitle: true,
      hideTitle: true,
      title: ["FINNS", "DEPARTURE", "PASS"],
      list_style: "service-detail-departurepass",
      menu: [],
      color: {
        text: "text-[#E5823B]",
        background: "text-white bg-[#E5823B]",
        background_light: "bg-[#DF5B111A]"
      }
    },
    fitnesscentrepass: {
      hasDarkMode: true,
      hasBottomContent: true,
      hasCustomPricing: true,
      title: ["FINNS", "FITNESS CENTRE", "PASS"],
      title_style: "justify-start text-left",
      list_style: "service-detail-splash",
      menu: [],
      color: {
        text: "text-[#00AEB7]",
        background: "bg-white",
        background_light: "bg-[#00AEB71A]"
      }
    },
    default: {
      title: [],
      list_style: "service-detail-departurepass",
      menu: [],
      color: {
        text: "text-[#E5823B]",
        background: "text-white bg-[#E5823B]",
        background_light: "bg-[#DF5B111A]"
      }
    }
  }

  return category_list[slug] ?? category_list.default
}