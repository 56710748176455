// React
import { Fragment } from "react"

// API
import { useQuery } from "react-query"
import { Api } from "../api"

// Components
import { ActionButton } from "components"

// Icons
import { TbArrowRight } from "react-icons/tb"

// Third-Party Libraries
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

export function CompanySection() {
  // API
  const fetchData = useQuery(['daypasses', 'grid-companies'], Api.grid_companies)

  if (fetchData.isLoading) {
    return (
      <div className="text-center my-5">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  } else {
    return (
      <Fragment>
        <h3 className="font-bold text-center pb-3">FINNS Bali Facilities You Can Enjoy with a Day Pass</h3>
        <CompanyList data={fetchData.data} />
      </Fragment>
    )
  }
}

function CompanyList({ data }) {
  return (
    <Swiper
      navigation
      slidesPerView="auto"
      spaceBetween={10}
      modules={[Navigation]}
    >
      {data.map((item, key) => (
        <SwiperSlide key={key} className="max-w-[318px]">
          <div className="w-[318px] flex flex-col items-center">
            <img
              src={item.background}
              alt={`Logo ${item.name}`}
              className="rounded-xl w-full aspect-square object-cover"
            />

            <img
              src={item.logo}
              alt={`Logo ${item.name}`}
              className="h-[100px]"
            />

            <a
              href={item.website}
              target="_blank"
              rel="noreferrer"
            >
              <ActionButton
                color="ghost"
                className="w-full whitespace-nowrap px-8 bg-white rounded-full text-black border-gray-300 font-bold"
              >
                Learn More <TbArrowRight />
              </ActionButton>
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}