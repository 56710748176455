// Components
import { ActionButton, ImageSlider } from "components"

// Configs
import { special } from "Config"

// Third-Party Libraries
import { Link } from "react-router-dom"

export function ServiceItem({
  best_for,
  detail,
  image,
  image_slider,
  is_sale,
  name,
  price,
  slug
}) {
  return (
    <div
      className="w-[300px] rounded-3xl p-0.5 bg-origin-border"
      style={{
        backgroundImage: `linear-gradient(white, white), linear-gradient(to right, ${is_sale ? "#33C5F9, #533BE7" : "#E9E9E9, #E9E9E9"})`,
        backgroundClip: "content-box, border-box"
      }}
    >
      <section className="rounded-3xl p-4 bg-origin-border h-full flex flex-col justify-between gap-3">
        <section>
          <section className="min-h-[60px] mb-2 flex flex-col justify-center gap-1">
            <section className="relative">
              <div className="text-xl font-extrabold text-center">{name}</div>

              {is_sale && (
                <img
                  src={special}
                  alt="Special"
                  className="w-[70px] absolute left-[-70px] top-[-4px]"
                />
              )}
            </section>

            <div className="text-error font-bold text-center">{is_sale}</div>
          </section>

          <ImageSlider
            loop
            style={{
              width: "100%",
              height: 180,
              "--swiper-navigation-size": "20px"
            }}
          >
            <ImageSlider.Item>
              <img className="w-full h-[180px] object-cover rounded-2xl" src={image} alt={name} />
            </ImageSlider.Item>

            {image_slider?.map((item, key) => (
              <ImageSlider.Item key={key}>
                <img className="w-full" height={180} style={{ objectFit: "cover", backgroundPosition: "center" }} src={`${process.env.REACT_APP_BASE_URL}${item}`} alt={`${name}-slider-${key}`} />
              </ImageSlider.Item>
            ))}
          </ImageSlider>

          <div className="service-detail text-gray-500 my-3" dangerouslySetInnerHTML={{ __html: best_for }} />

          <section className="h-[210px] overflow-auto">
            {Boolean(detail && detail !== "&nbsp") && <div className="checkmark text-left pt-3" dangerouslySetInnerHTML={{ __html: detail }} />}
          </section>
        </section>

        <section className="flex flex-col gap-3">
          <section className="text-center">
            <div className={`text-sm text-gray-600 ${is_sale && "invisible"}`}>Starting at just</div>
            <div className="text-2xl font-bold">IDR {price.toLocaleString()}</div>
          </section>

          <section className="flex flex-col gap-1">
            <Link to={`/${slug}`}>
              <ActionButton
                color="ghost"
                className="rounded-full text-white w-full bg-black"
              >
                Buy Now
              </ActionButton>
            </Link>

            <a
              href={`/inclusions/${slug}`}
              target="_blank"
              rel="noreferrer"
              className={`${is_sale && "invisible"}`}
            >
              <ActionButton color="ghost" className="bg-transparent rounded-full w-full">Inclusions & Prices</ActionButton>
            </a>
          </section>
        </section>
      </section>
    </div>
  )
}