// Components
import { ActionButton } from "components/buttons"

// Icons
import { TbMinus, TbPlus, TbTrash } from "react-icons/tb"

export const CustomNumberInput = ({
	min = "",
	max = "",
	value,
	onChange,
}) => {
	// Variables
	const isMin = Boolean(min != null && value <= parseInt(min))
	const isMax = Boolean(max != null && value >= parseInt(max))

	const decrease = () => {
		if (isMin) {
		} else {
			onChange(parseInt(value ?? "0") - 1)
		}
	}

	const increase = () => {
		if (isMax) {
		} else {
			onChange(parseInt(value ?? "0") + 1)
		}
	}

	return (
    <section className="flex items-center">
      <ActionButton
        type="button"
        color="ghost"
        className="px-50 btn-circle mr-3"
        size="sm"
        disabled={isMin}
        onClick={() => onChange(0)}
      >
        <TbTrash size={20} />
      </ActionButton>

      <ActionButton
        type="button"
        color={isMin ? "secondary" : "ghost"}
        className="px-50 border-gray-300 btn-circle"
        size="sm"
        disabled={isMin}
        onClick={decrease}
      >
        <TbMinus size={10} />
      </ActionButton>

      <div className="min-w-[50px] text-center">{value}</div>

      <ActionButton
        type="button"
        color={isMax ? "secondary" : "ghost"}
        className="px-50 border-gray-300 btn-circle"
        size="sm"
        disabled={isMax}
        onClick={increase}
      >
        <TbPlus />
      </ActionButton>
    </section>
	)
}
