// Configs
import { logo } from "Config"

export const ViewSection = ({
  errorCode = "",
  errorMessage = "",
  image
}) => {
  return (
    <section
      className='flex flex-col justify-center py-3'
      style={{
        minHeight: '100vh',
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${image})`,
        backgroundRepeat: 'no-repeat',
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize:  'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className='container' style={{ marginBottom: 150 }}>
        <img className='mb-5 w-[200px]' src={logo} alt="Logo" />

        <section className='error'>
          <div className='text-white m-0 md:text-[100px] text-[50px]'>ERROR</div>
          <div className='text-white m-0 font-extrabold md:text-[150px] text-[75px]'>{errorCode}</div>
          <div className='text-white mt-3 md:text-[30px] text-[15px]'>{errorMessage}</div>
        </section>
      </div>
    </section>
  )
}