import "./index.css"

// React
import { useEffect, useState } from "react"

// Third-Party Library
import { BrowserRouter, Routes, Route, useParams, useNavigate } from "react-router-dom"

// Routers
import { DayPassesRouter, ErrorRouter, MiscRouter } from "routers"

// API
import { SettingApi } from "api"

// Views
import Inclusions from "views/Inclusions"
import { Maintenance } from "views/errorView"

// Configs
import { logo } from "Config"

// Components
import { Loading } from "components"

const Main = () => {
  // Hooks
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false
  })

  useEffect(() => {
    SettingApi.maintenance_mode().then(res => {
      // Variables
      const isMaintenance = !Boolean(res)
      const secret_key = localStorage.getItem('secret_key')

      if (isMaintenance && secret_key) {
        SettingApi.secret_key().then(res => {
          if (res === secret_key) {
            setStatus({
              isLoading: false,
              isError: !isMaintenance
            })
          } else {
            setStatus({
              isLoading: false,
              isError: isMaintenance
            })
          }
        })
      } else {
        setStatus({
          isLoading: false,
          isError: isMaintenance
        })

        localStorage.removeItem('secret-key')
      }
    }).catch(() => {
      setStatus({
        isLoading: false,
        isError: true
      })
    })
  }, [])

  if (status.isLoading) {
    return (
      <div className="position-relative" style={{ height: '100vh' }}>
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <img alt="Logo" src={logo} height={120} /><br />
            <Loading className="text-primary" />
        </div>
      </div>
    )
  } else if (status.isError) {
    return <Maintenance />
  } else {
    return (
      <Routes>
        <Route path="inclusions/:slug" element={<Inclusions />} />
        <Route path="error/*" element={<ErrorRouter baseLink="/error" />} />
        <Route path="misc/*" element={<MiscRouter baseLink="/misc" />} />
        <Route path="*" element={<DayPassesRouter baseLink="/" />} />
      </Routes>
    )
  }
}

const Super = () => {
  // Hooks
  const navigate = useNavigate()
  const { key } = useParams()

  useEffect(() => {
    console.log("Fetching unique key...")

    SettingApi.secret_key().then(res => {
      if (res === key) {
        localStorage.setItem("secret_key", res)
        console.log("Fetching succeeded")
        navigate('/', { replace: true})
      } else {
        localStorage.removeItem("secret_key")
        console.log("Key unauthenticated")
        throw new Error()
      }
    }).catch(() => {
      console.log("Fetching failed")
    })

    // eslint-disable-next-line
  }, [])

  return null
}

const App = () => {
  useEffect(() => {
    console.log(require('../package.json').version)
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="super/:key" element={<Super />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App