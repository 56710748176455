// React
import { forwardRef } from "react"

// Third-Party Library
import ReactAsync from "react-select/async"

export const Select = forwardRef(({
  errors,
  id = Math.random().toString(36).substring(2, 7),
  label = "",
  onChange,
  options = [],
  placeholder = "Select...",
  loading,
  defaultValue,
  disabled = false,
  noMargin = false,
  ...rest
}, ref) => {
  const filterColors = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue))
    }, 1000)
  }

  const placeholderStyles = "text-gray-500 pl-1 py-0.5"
  const selectInputStyles = "pl-1 py-0.5"
  const valueContainerStyles = "p-1 gap-1"
  const singleValueStyles = "leading-7 ml-1"
  const multiValueStyles = "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5"
  const multiValueLabelStyles = "leading-6 py-0.5"
  const multiValueRemoveStyles = "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md"
  const indicatorsContainerStyles = "p-1 gap-1"
  const clearIndicatorStyles = "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800"
  const indicatorSeparatorStyles = "bg-gray-300"
  const dropdownIndicatorStyles = "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black"
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg"
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm"
  const noOptionsMessageStyles = "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm"

  return (
    <div className={`form-control w-full noMargin ? "mb-0" : "mb-3"`}>
      {label && <span className="label-text font-bold">{label}</span>}

      {loading ? (
        <input className="w-full" placeholder="Please wait..." disabled />
      ) : (
        <ReactAsync
          {...rest}
          ref={ref}
          id={`input-${id}`}
          isClearable={false}
          styles={{ borderColor: 'red' }}
          classNames={{
            placeholder: () => placeholderStyles,
            input: () => selectInputStyles,
            valueContainer: () => valueContainerStyles,
            singleValue: () => singleValueStyles,
            multiValue: () => multiValueStyles,
            multiValueLabel: () => multiValueLabelStyles,
            multiValueRemove: () => multiValueRemoveStyles,
            indicatorsContainer: () => indicatorsContainerStyles,
            clearIndicator: () => clearIndicatorStyles,
            indicatorSeparator: () => indicatorSeparatorStyles,
            dropdownIndicator: () => dropdownIndicatorStyles,
            menu: () => menuStyles,
            groupHeading: () => groupHeadingStyles,
            noOptionsMessage: () => noOptionsMessageStyles,
          }}
          placeholder={placeholder}
          loadOptions={loadOptions}
          defaultOptions
          onChange={onChange}
          defaultValue={defaultValue}
          isDisabled={disabled}
          isLoading={loading}
        />
      )}

      {errors && (
        <label className="label">
          <span className="label-text-alt text-error">{errors}</span>
        </label>
      )}
    </div>
  )
})