// Components
import { Navbar } from "components"

// Third-Party Library
import { Navigate, Route, Routes } from "react-router-dom"

// Config
import menu from "navigations/miscNav"

// Config
import { logo } from "Config"

const MiscRouter = ({ baseLink }) => {
  return (
    <div className="min-vh-100 flex flex-col">
      {/* Navbar */}
      <Navbar
        sticky="top"
        logo={logo}
        baseLink={baseLink}
        noColorChange
      />

      {/* Routes */}
      <section>
        <Routes>
          {menu.map((val, index) => {
            return (
              <Route
                key={index}
                path={val.link}
                element={<val.component />}
              />
            )
          })}

          <Route path="*" element={<Navigate to="/error/404" replace />} />
        </Routes>
      </section>
    </div>
  )
}

export default MiscRouter