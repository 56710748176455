import service from "service"

const base_url = `${process.env.REACT_APP_BASE_URL_API}/setting`
class AtributApi {
  async tax_services() {
    const data = await service.get(`${base_url}/tax_services`)
    return data.data
  }

  async maintenance_mode() {
    const data = await service.get(`${base_url}/maintenance_mode`)
    return data.data
  }

  async additional_cost() {
    const data = await service.get(`${base_url}/additional_cost`)
    return parseInt(data.data ?? 0)
  }

  async secret_key() {
    const data = await service.get(`${base_url}/secret_key`)
    return data.data
  }

  async personal_insurance() {
    const data = await service.get(`${base_url}/personal_insurance`)
    return data.data
  }
}

export default new AtributApi()