import { PageNotFound } from "views/errorView"

const menu = [
  {
    link: "/404",
    component: <PageNotFound />,
    isMenu: true
  }
]

export default menu