// React
import { Fragment, useEffect, useState } from "react"

// API
import { useMutation } from "react-query"
import { Api } from "../api"

// Components
import { ActionButton, Modal } from "components"

// Third-Party Libraries
import useToggle from "@react-hook/toggle"
import { QrReader } from "react-qr-reader"

// Utils
import { toast } from "functions"

export function ModalSection({ onSetBooking }) {
  // Hooks
  const [code, setCode] = useState(null)
  const [isOpen, toggle] = useToggle(false, true)

  // API
  const fetchCode = useMutation(value => Api.booking(value),
    {
      onSuccess: res => {
        toast("success", "Success", "Scan Booking Code Success")
        onSetBooking(res)
      },
      onError: err => toast("danger", "Error", err.response.data.message)
    }
  )

  useEffect(() => {
    if (code) {
      alert("Success")
      toggle()
      fetchCode.mutate(code)
    }

    return () => {
      setCode(null)
    }

    // eslint-disable-next-line
  }, [code])

  return (
    <Fragment>
      <ActionButton block className="text-white" color="primary" onClick={toggle} loading={fetchCode.isLoading}>Scan Booking Code</ActionButton>

      {isOpen && (
        <Modal show showClose onClose={toggle}>
          <Modal.Header>Scan Booking Code</Modal.Header>

          <Modal.Body>
            <QrReader
              constraints={{
                facingMode: "environment",
                aspectRatio: 1/1
              }}
              scanDelay={300}
              onResult={(result) => {
                if (result) {
                  setCode(result.text)
                }
              }}
              style={{ width: '100%' }}
            />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}