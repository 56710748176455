// React
import { Fragment } from 'react'

// Assets
import Image from 'assets/images/error.jpeg'

// View Components
import { ViewSection } from 'components'

const Maintenance = () => (
  <ViewSection
    image={Image}
    errorCode="503"
    errorMessage={<Fragment>We're Under Maintenance <br /> Sorry for the inconvenience but we're <br /> performing some maintenance at the moment</Fragment>}
  />
)
export default Maintenance
