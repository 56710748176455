// Components
import { Checkbox, Terms } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function TermsConditions({ waiver }) {
  // Form
  const { control } = useFormContext()

  return (
    <section className="my-3">
      <Controller
        name="terms"
        control={control}
        render={method => (
          <Checkbox
            onChange={() => method.field.onChange(!method.field.value)}
            value={method.field.value}
            errors={method.formState.errors?.terms?.message}
          >
            I accept all FINNS Recreation Club & FINNS Beach Club Booking & venue <Terms.Condition /> ***
          </Checkbox>
        )}
      />

      <Controller
        name="policy"
        control={control}
        render={method => (
          <Checkbox
            onChange={() => method.field.onChange(!method.field.value)}
            value={method.field.value}
            errors={method.formState.errors?.policy?.message}
          >
            I understand and accept the FINNS Recreation Club & FINNS Beach Club Booking face & head tattoo <Terms.Policy /> ***
          </Checkbox>
        )}
      />

      <Controller
        name="waiver"
        control={control}
        render={method => (
          <Checkbox
            onChange={() => method.field.onChange(!method.field.value)}
            value={method.field.value}
            errors={method.formState.errors?.waiver?.message}
          >
            I agree to the {waiver}
          </Checkbox>
        )}
      />
    </section>
  )
}