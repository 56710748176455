// Components
import { ActionButton } from "components/buttons"

// Icons
import { TbMinus, TbPlus } from "react-icons/tb"

export const InputNumber = ({
	label = "",
	className = "",
	min = "",
	max = "",
	noMargin = false,
	value,
	onChange,
}) => {
	// Variables
	const isMin = Boolean(min != null && value <= parseInt(min))
	const isMax = Boolean(max != null && value >= parseInt(max))

	const decrease = () => {
		if (isMin) {
		} else {
			onChange(parseInt(value ?? "0") - 1)
		}
	}

	const increase = () => {
		if (isMax) {
		} else {
			onChange(parseInt(value ?? "0") + 1)
		}
	}

	return (
		<div className={`${noMargin ? 'mb-0' : 'mb-1'} ${className}`}>
			{label && (
				<label className="label">
					<span className="label-text font-bold">{label}</span>
				</label>
			)}

			<section className="join w-[130px]">
				<ActionButton
					type="button"
					color={isMin ? "secondary" : "primary"}
					className="join-item px-50"
					size="sm"
					disabled={isMin}
					onClick={decrease}
				>
					<TbMinus color="white" size={10} />
				</ActionButton>

				<input value={value} className="input input-bordered input-sm grow join-item min-w-[0px] w-[1%] text-center" readOnly />

				<ActionButton
					type="button"
					color={isMax ? "secondary" : "primary"}
					className="join-item px-50"
					size="sm"
					disabled={isMax}
					onClick={increase}
				>
					<TbPlus />
				</ActionButton>
			</section>
		</div>
	)
}
