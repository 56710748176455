import service from "service"

const base_url = process.env.REACT_APP_BASE_URL_API
class HomeApi {
  async promo_list() {
    const res = await service.get(`${base_url}/list/promo`)

    return res.data.data.map(item => {
      // Vars
      const serviceData = item.service

      return {
        description: item.description,
        image: `${process.env.REACT_APP_BASE_URL}${item.service.image}`,
        name: item.service.name,
        notes: item.notes,
        slug: item.category.slug,
        valid_date: item.valid_date,
        price: parseFloat(serviceData.price),
        category: {
          slug: item.category.slug
        },
        ticket: {
          category_id: serviceData.category_id,
          choosed_service: serviceData.id,
          complimentary_qty: serviceData.complimentary_qty,
          image: `${process.env.REACT_APP_BASE_URL}${serviceData.image}`,
          label: `FLASH SALE (${serviceData.name})`,
          name: serviceData.name,
          price: parseFloat(serviceData.price)
        }
      }
    })
  }
}

export default new HomeApi()