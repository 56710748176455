// API
import { useQuery } from "react-query"
import { Api } from "./api"

// Components
import { Tab } from "@headlessui/react"
import { FetchingStatus } from "components"
import { FitnessFormSection, FormSection } from "./components"

// Utils
import { waiver_list } from "./utils"

export default function WalkInReservation() {
  return (
    <section className="max-w-[48rem] mt-3 mx-auto">
      <h4 className="text-center font-bold">WALK IN RESERVATION</h4>

      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-black/40 p-1 mx-auto">
          {waiver_list.map((item, key) => <Tab key={key} className={({ selected }) => `w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-primary ${selected ? "bg-white text-primary shadow" : "text-gray-100 hover:bg-white/[0.12] hover:text-white"}`}>{item}</Tab>)}
        </Tab.List>

        <Tab.Panels className="mt-5">
          <Tab.Panel>
            <Splash />
          </Tab.Panel>
          <Tab.Panel>
            <Fitness />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </section>
  )
}

function Splash() {
  // API
  const fetch = useQuery(['list', 'category', "WALKIN"], () => Api.category({ type: "WALKIN" }))

  if (fetch.isLoading || fetch.isError) {
    return <FetchingStatus loading={fetch.isLoading} />
  } else {
    return <FormSection data={fetch.data} />
  }
}

function Fitness() {
  // API
  const fetch = useQuery(['list', 'category', "FITNESS"], () => Api.category({ type: "FITNESS" }))

  if (fetch.isLoading || fetch.isError) {
    return <FetchingStatus loading={fetch.isLoading} />
  } else {
    return <FitnessFormSection data={fetch.data} />
  }
}