export function useDataWithWaiver(data) {
  const waiverHeader = data.reduce((prev, cur) => {
    return [...new Set(prev.concat(cur.waiver_variant))]
  }, [])

  const formData = data.map(item => {
    item.waiver_variant.map(val => {
      item[val] = 0

      return null
    })

    return {
      ...item,
      category_id: item.id
    }
  })

  return { formData, waiverHeader }
}