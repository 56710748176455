import service from "service"

const base_url = process.env.REACT_APP_BASE_URL_API
class Api {
  async companies(params) {
    const res = await service.get(`${base_url}/companies/day-passes`, { params })
    const data = res.data.data

    return {
      ...data,
      category: data.category.map(val => {
        return {
          ...val,
          online_service: val.online_service.map(val => {
            return {
              ...val,
              price: parseInt(val.price)
            }
          })
        }
      })
    }
  }

  request_store(value) {
    return service.post(`${base_url}/enquire`, value)
  }

  async grid_companies() {
    const res = await service.get(`${base_url}/grid-companies`)
    const data = res.data.data

    return data.map(val => {
      return {
        ...val,
        background: `${process.env.REACT_APP_BASE_URL}${val.background}`,
        logo: `${process.env.REACT_APP_BASE_URL}${val.logo}`
      }
    })
  }

  active_campaign(value) {
    return service.post(`${base_url}/active-campaign`, value)
  }
}

export default new Api()