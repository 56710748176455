import service from "service"

const base_url = `${process.env.REACT_APP_BASE_URL_API}/booking`
class Api {
  async summary(params) {
    const res = await service.get(`${base_url}/summary`, { params })
    return res.data.data
  }
}

export default new Api()