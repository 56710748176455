// React
import { Fragment, useState } from 'react'

// Components
import { Modal } from 'components/actions'

// Third-Party Libraries
import { Link } from 'react-router-dom'

function Terms({ children }) {
  <Fragment>{children}</Fragment>
}

function Condition() {
  return (
    <span className="pl-1">
      <a
        href="https://bookings.finnsbeachclub.com/superadmin/termcondition/get/fr-general"
        target="_blank"
        rel="noreferrer"
        className="font-bold underline"
      >
        terms and conditions.
      </a>
    </span>
  )
}

function FitnessWaiver() {
  return (
    <span className="pl-1">
      <a
        href={`${process.env.REACT_APP_BASE_URL}/mentahan/fitness-waiver.pdf`}
        target="_blank"
        rel="noreferrer"
        className="font-bold underline"
      >
        Fitness Centre Liability Waiver & Fitness Centre Code of Conduct.
      </a>
    </span>
  )
}

function Policy() {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  const toggle = () => {
    setOpen(prev => !prev)
  }

  return (
    <Fragment>
      <Link to="#" className="cursor-pointer select-none font-bold underline" onClick={toggle}>face & head tattoo policy.</Link>

      {isOpen && (
        <Modal show showClose onClose={toggle}>
          <Modal.Header>Policy</Modal.Header>

          <Modal.Body className="text-center">
            <img src={require('assets/images/policy.jpeg')} alt="Policy" className="w-full" />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}

function SplashWaiver() {
  return (
    <span className="pl-1">
      <a
        href="https://bookings.finnsbeachclub.com/pdf/tc/TC__sw-general.pdf"
        target="_blank"
        rel="noreferrer"
        className="font-bold underline"
      >
        Splash Waiver.
      </a>
    </span>
  )
}

Terms.Condition = Condition
Terms.FitnessWaiver = FitnessWaiver
Terms.Policy = Policy
Terms.SplashWaiver = SplashWaiver

export { Terms }