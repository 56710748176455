import service from "service"

const base_url = process.env.REACT_APP_BASE_URL_API
class Api {
  async show(value) {
    const res = await service.get(`${base_url}/day-passes/${value}`)
    const data = res.data.data

    return {
      ...data,
      services: data.services.map(val => {
        return {
          ...val,
          label: val.name,
          price: parseInt(val.price),
          pax: null,
          choosed_service: val.id
        }
      })
    }
  }

  async categories(value, params) {
    const res = await service.get(`${base_url}/categories/${value}`, { params })
    const data = res.data.data

    return {
      ...data,
      online_service: data.online_service.map(item => {
        return {
          category_id: item.category_id,
          choosed_service: item.id,
          complimentary_qty: item.complimentary_qty ?? 0,
          image: `${process.env.REACT_APP_BASE_URL}${item.isPromo ? item.image : item.category.image}`,
          isPromo: item.isPromo,
          label: `${item.category.name} (${item.name})`,
          name: item.name,
          price: parseInt(item.price)
        }
      })
    }
  }

  async blackout_date(params) {
    const res = await service.get(`${base_url}/list/blackout-date`, { params })

    return res.data.data.map(item => {
      return {
        ...item,
        start_date: item.exclusion_date,
        label: item.shortdesc
      }
    })
  }
}

export default new Api()