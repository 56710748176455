// Components
import { Loading } from "components/feedbacks"

const DataStatus = ({
  children,
  loading = false,
  noMargin = false
}) => {
  return (
    <div className={`text-center ${noMargin ? 'my-0' : 'my-5'}`}>
      {loading && (
        <div className="me-1">
          <Loading />
        </div>
      )}
      <span>{children}</span>
    </div>
  )
}

export default DataStatus
