// React
import { Fragment } from "react"

// Third-Party Library
import { Route, Routes } from "react-router-dom"

// Config
import menu from "navigations/errorNav"

const ErrorRouter = () => (
  <Fragment>
    {/* Routes */}
    <Routes>
      {menu.map((val, index) => (
        <Route key={index} path={val.link} element={val.component} />
      ))}
    </Routes>
  </Fragment>
)

export default ErrorRouter