const Navbar = ({ logo = "" }) => {
  return (
    <div className="navbar bg-black">
      <section className="container">
        <a href={process.env.REACT_APP_FINNSREC_URL}>
          <section className="btn btn-ghost">
            <img alt="logo" src={logo} style={{ height: 40 }} />
          </section>
        </a>
      </section>
    </div>
  )
}

export default Navbar