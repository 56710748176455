// Swiper
import 'swiper/css'
import 'swiper/css/navigation'
import { Autoplay, Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

function ImageSlider({ children, autoPlayDelay = 0, loop = false, style, autoHeight = false }) {
  return (
    <Swiper
      navigation
      autoHeight={autoHeight}
      loop={loop}
      className="mySwiper"
      autoplay={autoPlayDelay ? {
        delay: autoPlayDelay,
        stopOnLastSlide: autoPlayDelay ? true : false
      } : false}
      modules={[Autoplay, Navigation]}
      style={style}
    >
      {children}
    </Swiper>
  )
}

ImageSlider.Item = SwiperSlide

export { ImageSlider }