// ** Third Party Components
import { toast as reactToast } from 'react-toastify'

const toast = (
  status = "",
  message = "",
  children = ""
) => {
  return reactToast[status](
    <div>
      <div className='font-bold'>{message}</div>
      <div>{children}</div>
    </div>
  , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export default toast