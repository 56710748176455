import service from "service"

const base_url = process.env.REACT_APP_BASE_URL_API
class Api {
  async check_code(params) {
    const res = await service.get(`${base_url}/check/ultimate-code`, { params })
    return res.data.data
  }
}

export default new Api()