// React
import { forwardRef } from "react"

export const Checkbox = forwardRef(function Checkbox({
  onChange,
  children,
  errors,
  value
}, ref) {
  return (
    <div className="form-control">
      <label className="label py-1 cursor-pointer flex justify-start gap-3">
        <input
          ref={ref}
          type="checkbox"
          className="checkbox"
          onChange={onChange}
          checked={Boolean(value)}
        />

        <span>{children}</span> 
      </label>

      {errors && <p className="text-error dark:text-error mt-2 text-sm">{errors}</p>}
    </div>
  )
})