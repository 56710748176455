// API
import { useQuery } from "react-query"
import { AtributApi } from "api"

// Components
import { Select } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function Nationality() {
  // API
  const fetchCountries = useQuery(['atribut', 'countries'], AtributApi.countries)

  // Hooks
  const { control } = useFormContext()

  return (
    <Controller
      name="nationality"
      control={control}
      render={method => (
        <Select
          label={<div>Nationality <span className="text-error">*</span></div>}
          placeholder="Select Nationality..."
          loading={fetchCountries.isLoading}
          defaultValue={fetchCountries?.data?.find(val => val.value === method.field.value)}
          options={fetchCountries?.data}
          onChange={e => method.field.onChange(e.value)}
          errors={method.formState.errors?.nationality?.message}
        />
      )}
    />
  )
}