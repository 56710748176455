// SweetAlert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const sweetAlert = ({
	title = 'Are you sure?',
	icon = 'warning',
	text = "",
	html = "",
	confirmButtonText = "Yes",
	cancelButtonText = "No",
	noCancelButton = false
}) => {
	const MySwal = withReactContent(Swal)

	return MySwal.fire({
		title,
		text,
		icon,
		html,
		showCancelButton: !noCancelButton,
		confirmButtonText,
		cancelButtonText,
		customClass: {
			confirmButton: 'btn btn-primary text-white',
			cancelButton: 'btn btn-outline btn-error ms-1'
		},
		buttonsStyling: false
	})
}

export default sweetAlert