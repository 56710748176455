// React
import { useState } from "react"

// API
import { Api } from "../../api"

export function useQuestionnaire() {
  // Hooks
  const [isLoading, setLoading] = useState(false)

  const refetch = () => {
    return new Promise((resolve) => {
      setLoading(true)

      Api.questionnaire().then(res => {
        resolve(res.map(item => {
          return {
            answer: false,
            question: item.question
          }
        }))
      }).catch(() => {
        resolve([])
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return { isLoading, refetch }
}