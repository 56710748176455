// React
import React, { Fragment, useState, useEffect } from 'react'

// Components
import { ActionButton, Carousel } from 'components'

// Icons
import { FaCheckSquare, FaExclamationCircle } from 'react-icons/fa'

// Third-Party Library
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from "prop-types"

// Utils
import { dateConvert } from 'functions'

const Day = styled.div`
  width: 14.2%;
  cursor: pointer;
`

const FullCalendar = props => {
  // Variables
  const today = new Date()
  const dateSelected = new Date(props.value)
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_OF_THE_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

  // States
  const [date, setDate] = useState(dateSelected)
  const [dateHovered, setDateHovered] = useState(today)
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date))

  useEffect(() => {
    setMonth(date.getMonth())
    setYear(date.getFullYear())
    setStartDay(getStartDayOfMonth(date))
  }, [date])

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay()
    return startDate === 0 ? 7 : startDate
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }

  // Variables
  const days = isLeapYear(year) ? DAYS_LEAP : DAYS
  const countDays = days[date.getMonth()] + (startDay - 1)
  const remainingDays = 7 - (countDays % 7)

  const SelectedDate = () => (
    <section className='absolute inset-0 flex items-center justify-center'>
      <div className="text-blue-500 mt-3 flex flex-col items-center">
        <FaCheckSquare size={20} />
        <div className='text-center uppercase sm:block hidden' style={{ fontSize: '.625rem' }}>Selected Date</div>
      </div>
    </section>
  )

  const HoveredDate = () => (
    <div className="absolute bottom-0 inset-x-0 text-center text-blue-500" style={{ width: '95%' }}>
      <div className='flex flex-lg-row flex-col uppercase justify-center'>
        <div className='px-1' style={{ fontSize: '.625rem' }}>Choose</div>
        <span className='md:block hidden' style={{ fontSize: '.625rem' }}>This Date</span>
      </div>
    </div>
  )

  const getMonthDifference = () => {
    if (props.maxDate && today <= props.maxDate) {
      const data = (props.maxDate.getMonth() - today.getMonth() + (12 * (props.maxDate.getFullYear() - today.getFullYear()))) + 1

      return data
    } else {
      return 10
    }
  }

  return (
    <Fragment>
      <section className='mb-10'>
        <Carousel>
          {[...Array(getMonthDifference())].map((_val, index) => {
            // Variables
            const base = new Date()
            const data = new Date(base.getFullYear(), base.getMonth(), 1)
            const value = data.setMonth(data.getMonth() + index)
            const selectedMonth = Boolean(date.getMonth() === data.getMonth() && date.getFullYear() === data.getFullYear())

            return (
              <div key={index} className={`card cursor-pointer border rounded-md ${selectedMonth && "border-primary"}`} onClick={() => setDate(data)}>
                <div className="card-body py-0 text-center select-none">
                  <small className={`${selectedMonth && "text-primary"}`}>{new Date(value).toLocaleDateString('en-US', { month: 'short' })}</small>
                  <div className={`font-bold ${selectedMonth && "text-primary"}`}>{new Date(value).toLocaleDateString('en-US', { year: 'numeric' })}</div>
                </div>
              </div>
            )
          })}
        </Carousel>
      </section>

      <h3 className='text-center'>
        {date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
      </h3>

      <div className='flex flex-wrap'>
        {DAYS_OF_THE_WEEK.map((d, index) => (
          <Day key={d} className={`text-center py-1 border-top border-bottom ${index === 0 && "border-start"} ${index === DAYS_OF_THE_WEEK.length - 1 && "border-end"}`}>
            <strong>{d}</strong>
          </Day>
        ))}

        {Array(countDays).fill(null).map((_, index) => {
          // Variables
          const d = index - (startDay - 2)
          const todayDate = new Date(today.setHours(0, 0, 0, 0))
          const thisDate = new Date(year, month, d)
          const pastDate = thisDate < todayDate
          const fillBorder = new Date(thisDate).setDate(thisDate.getDate() + (7 - (index % 7))) > today
          const isToday = String(thisDate) === String(todayDate)
          const isSelected = dateConvert(thisDate).default.format === dateConvert(dateSelected).default.format
          const isHovered = String(thisDate) === String(dateHovered)
          const isEvent = props.events.find(val => {
            // Variables
            const date = moment(thisDate)
            const start_date = moment(val.start_date, "YYYY-MM-DD")
            const end_date = moment(val.end_date, "YYYY-MM-DD")

            return Boolean(date.isSame(start_date) || date.isBetween(start_date, end_date) || date.isSame(end_date))
          })

          return (
            <Day
              key={index}
              className={`${Boolean(!pastDate || fillBorder) && "border"} relative`}
              style={{ height: Boolean(!pastDate || fillBorder) ? 120 : 0 }}
              onClick={() => {
                if (d > 0 && !pastDate) {
                  if (isEvent) {
                    if (isEvent.url) {
                      window.open(isEvent.url, '_blank')
                    }
                  } else {
                    props.onClick(new Date(year, month, d))
                  }
                }
              }}
              onMouseEnter={() => {
                if (d > 0 && !pastDate) {
                  setDateHovered(new Date(year, month, d))
                }
              }}
            >
              {d > 0 ? (
                <Fragment>
                  <div className="absolute top-0 left-0" style={{ width: '100%' }}>
                    <div className='m-2 flex md:flex-row flex-col'>
                      {!pastDate && (
                        <section className='whitespace-nowrap'>
                          {d} <span className='md:visible invisible'>{`${date.toLocaleDateString('en-US', { month: 'short' })}`}</span>
                        </section>
                      )}
                    </div>
                  </div>

                  <section>
                    {isEvent && !pastDate ? (
                      <div className="absolute bottom-0 inset-x-0 w-full px-[3px]">
                        <ActionButton block outline type="button" color='error' className='group/item'>
                          <div className='flex items-center justify-center group-hover/item:text-white'>
                            <span className='uppercase align-middle ms-25 pr-1 lg:block hidden' style={{ fontSize: '.625rem' }}>{isEvent.label}</span>
                            <FaExclamationCircle size={20} />
                          </div>
                        </ActionButton>
                      </div>
                    ) : isToday && (
                      <div className='lg:block hidden'>
                        <div className="absolute bottom-0 inset-x-0" style={{ width: '95%' }}>
                          <ActionButton block outline type="button" color='ghost' className="group/item border-blue-500 hover:bg-blue-500 lg:block hidden">
                            <div className='flex items-center justify-center text-blue-500 group-hover/item:text-white'>
                              <span className='uppercase align-middle ms-25 pr-1' style={{ fontSize: '.625rem' }}>Choose Today </span>
                              <FaCheckSquare size={20} />
                            </div>
                          </ActionButton>
                        </div>
                      </div>
                    )}

                    <div className='block lg:hidden'>
                      {isSelected && !isEvent ? <SelectedDate /> : isHovered && !isEvent && <HoveredDate />}
                    </div>
                  </section>

                  {isSelected && !isToday && !isEvent ? <SelectedDate /> : isHovered && !isToday && !isEvent && <HoveredDate />}
                </Fragment>
              ) : <div />}
            </Day>
          )
        })}

        {remainingDays && remainingDays < 7 && [...Array(remainingDays)].map((_, index) => {
          return (
            <Day
              key={index}
              className='border relative'
              style={{
                height: 120
              }}
            />
          )}
        )}
      </div>
    </Fragment>
  )
}

FullCalendar.propTypes = {
	value: PropTypes.string,
  onClick: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
	events: PropTypes.arrayOf(
		PropTypes.shape({
      date: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
		})
	)
}

FullCalendar.defaultProps = {
	events: []
}

export { FullCalendar }