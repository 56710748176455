// React
import { useEffect } from "react"

// Components
import { ActionButton, DataStatus, FetchingStatus } from "components"

// Icons
import { FiChevronLeft, FiDownload } from "react-icons/fi"
import { AiOutlineCheck } from "react-icons/ai"

// Third-Party Library
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

// API
import { useQuery } from "react-query"
import { Api } from "./apis"

const PaymentSuccess = () => {
  // Hooks
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // Variables
  const unique_id = searchParams.get('unique_id')

  useEffect(() => {
    if (!unique_id) {
      navigate("/", { replace: true })
    }

    // eslint-disable-next-line
  }, [])

  // Variables
  const barcode = location?.state?.barcode
  const bookingStatus = barcode ? "walk_in" : "online"
  const result = {
    online: {
      isShowProducts: true,
      title: "Payment Success",
      subtitle: "Please check your email to download your booking receipt",
      button: {
        icon: FiDownload,
        onButtonClick: () => window.open(`${process.env.REACT_APP_BASE_URL_API}/booking/receipt?unique_id=${unique_id}`, "_blank"),
        text: "Download Receipt"
      }
    },
    walk_in: {
      isShowProducts: false,
      title: "Register Success",
      button: {
        icon: FiChevronLeft,
        onButtonClick: () => navigate('/misc/walk-in-reservation', { replace: true }),
        text: "Go Back"
      }
    }
  }
  const Icon = result[bookingStatus].button.icon

  return (
    <div className="flex flex-col" style={{ minHeight: 800 }}>
      <div className="container my-auto">
        <section className="text-center">
          <div className="my-2 flex justify-center">
            <AiOutlineCheck size={30}  className="text-primary" />
          </div>

          <h3 className="font-extrabold">{result[bookingStatus].title}</h3>
          <h3 className="font-extrabold mb-4">{result[bookingStatus].subtitle}</h3>

          <ActionButton className="text-white" onClick={result[bookingStatus].button.onButtonClick}>
            <Icon size={14} />
            <span className='align-middle ms-1'>{result[bookingStatus].button.text}</span>
          </ActionButton>
        </section>

        {result[bookingStatus].isShowProducts && (
          <section className="my-3">
            <TableSection unique_id={unique_id} />
          </section>
        )}
      </div>
    </div>
  )
}

function TableSection({ unique_id }) {
  // Hooks
  const navigate = useNavigate()

  // API
  const fetchData = useQuery(
    [`dayPasses-success-${unique_id}`],
    () => Api.summary({ unique_id }),
    {
      onError: () => navigate("/error/404", { replace: true })
    }
  )

  // Variables
  const data = fetchData.data
  const currency = data?.purchase?.currency_code
  const tax = parseFloat(data?.tax ?? 0)

  useEffect(() => {
    if (window.location.pathname === "/success" && data?.details?.length > 0 && data.details[0].service.category.slug) {
      navigate(`/thank-you/${data.details[0].service.category.slug}?unique_id=${unique_id}`, { replace: true })
    }

    if (window.location.pathname !== "/success" && data) {
      // Variables
      const promo = data.booking_promo.map(item => {
        return item.promo.code
      })

      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          purchase: {
            transaction_id: unique_id,
            value: parseInt(data.total_ori ?? "0"),
            currency: "IDR",
            state: data.guest_nationality,
            items: data.details.map(item => {
              return {
                item_name: `${item.service.category.name} - ${item.service.name}`,
                item_id: item.service.id,
                price: parseInt(item.price_ori ?? "0"),
                coupon: promo?.join(", ") ?? ""
              }
            })
          }
        }
      })
    }

    // eslint-disable-next-line
  }, [data])

  const DataTable = ({ label = "", children }) => (
    <tr className="font-bold text-end">
      <td colSpan={3}>{label}:</td>
      <td>{children}</td>
    </tr>
  )

  if (fetchData.isError || fetchData.isLoading) {
    return (
      <div style={{
        marginTop: "20rem !important",
        marginBottom: "20rem !important"
      }}>
        <FetchingStatus loading={fetchData.isLoading} />
      </div>
    )
  } else {
    return (
      <section className="overflow-x-auto">
        <table className="table">
          <thead className="bg-light">
            <tr className="uppercase">
              <th>No</th>
              <th>Product</th>
              <th className="text-right">Price</th>
              <th className="text-right">Total</th>
            </tr>
          </thead>
    
          <tbody>
            {data.details?.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <DataStatus>No data available</DataStatus>
                </td>
              </tr>
            ) : data.details?.map((val, index) => {
              // Variables
              const isService = Boolean(val.type === "SERVICES")
    
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div>{parseInt(val.quantity)} x {val.service.service_name} {isService && `(${val.service.variant.name})`}</div>
                    <div>Booking Code: {data.booking_code}</div>
                    <div>Type: {val.type}</div>
                  </td>
                  <td className="text-end">{currency} {parseFloat(val.price).toLocaleString('en-US')}</td>
                  <td className="text-end">{currency} {parseFloat(val.price * val.quantity).toLocaleString('en-US')}</td>
                </tr>
              )
            })}
    
            <DataTable label="Subtotal">{currency} {parseFloat(data.subtotal).toLocaleString('en-US')}</DataTable>
            <DataTable label="Discount">{currency} {parseFloat(data.discount).toLocaleString('en-US')}</DataTable>
            {tax > 0 && <DataTable label="Government Tax & Service Charge">{currency} {tax.toLocaleString('en-US')}</DataTable>}
            <DataTable label="Personal Insurance">{currency} {parseFloat(data.insurance_ori ?? "0").toLocaleString('en-US')}</DataTable>
            <DataTable label="Grand Total">{currency}  {parseFloat(data.total).toLocaleString('en-US')}</DataTable>
          </tbody>
        </table>
      </section>
    )
  }
}

export default PaymentSuccess