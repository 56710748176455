// React
import { forwardRef } from "react"

export const Input = forwardRef(function Input({
	label = "",
	errors,
	children,
	value,
	type = "text",
	id = Math.random().toString(36).substring(2, 7),
	noMargin = false,
	noLabel = false,
	...rest
}, ref) {
	return (
		<div className="form-control">
			{label && <span className="label-text font-bold">{label}</span>}
			<input
				ref={ref}
				type={type}
				className={`w-full input input-bordered ${errors && "input-error"}`} {...rest}
			/>

			{errors && (
				<label className="label">
					<span className="label-text-alt text-error">{errors}</span>
				</label>
			)}
		</div>
	)
})
