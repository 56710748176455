import { UltimateExperience, WalkInReservation } from "views/miscView"

const menu = [
  {
    link: "/ultimate-experience",
    component: UltimateExperience
  },
  {
    link: "/walk-in-reservation",
    component: WalkInReservation
  }
]

export default menu