// Assets
import FlashSale from 'assets/images/banners/flash_sale.jpg'

// Components
import { ImageSlider } from "components"

export function Banner() {
  // Variables
  const images = [FlashSale]

  return (
    <ImageSlider
      autoHeight
      autoPlayDelay={3000}
    >
      {images.map((item, key) => (
        <ImageSlider.Item key={key}>
          <img src={item} alt="Banner" className="w-full" />
        </ImageSlider.Item>
      ))}
    </ImageSlider>
  )
}