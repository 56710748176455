// React
import { Fragment, useRef } from "react"

// Multi Carousel
import ReactCarousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css'

// Icons
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa"

const Carousel = ({
  className = "mb-2",
  responsive = {
    lg: 8,
    md: 5,
    sm: 3
  },
  children,
  ...rest
}) => {
  // Hooks
  const ref = useRef(null)

  return (
    <Fragment>
      <section className="mb-1">
        <div className="flex justify-between">
          <FaArrowCircleLeft style={{ cursor: 'pointer' }} onClick={() => ref.current.previous()} />
          <FaArrowCircleRight style={{ cursor: 'pointer' }} onClick={() => ref.current.next()} />
        </div>
      </section>

      <ReactCarousel
        {...rest}
        ref={ref}
        className={className}
        additionalTransfrom={0}
        arrows={false}
        draggable
        itemClass=""
        keyBoardControl
        renderButtonGroupOutside
        responsive={{
          desktop: {
            breakpoint: {
              max: 10000,
              min: 1024
            },
            items: responsive.lg - 1,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: responsive.sm - 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: responsive.md - 1,
            partialVisibilityGutter: 30
          }
        }}
        shouldResetAutoplay
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {children}
      </ReactCarousel>
    </Fragment>
  )
}

export { Carousel }