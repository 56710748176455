export function Loading({
  className = "",
  color = "",
  size = ""
}) {
  // Variables
  const colorProp = {
    primary: "text-primary",
    secondary: "text-secondary",
    accent: "text-accent",
    neutral: "text-neutral",
    info: "text-info",
    success: "text-success",
    warning: "text-warning",
    error: "text-error"
  }
  const sizeProp = {
    xs: "loading-xs",
    sm: "loading-sm",
    md: "loading-md",
    lg: "loading-lg"
  }

  return (
    <section className="text-center">
      <span className={`loading loading-spinner loading-lg ${size && sizeProp[size]} ${color && colorProp[color]} ${className}`}></span>
    </section>
  )
}