// React
import { Fragment, useRef, useState } from "react"

// API
import { Api as OrderApi } from "../../api"

// Components
import { ActionButton, Calendar, Input, InputNumber, Signature, Terms } from "components"
import { Gender } from "./Gender"
import { ModalSection } from "./ModalSection"
import { Nationality } from "./Nationality"
import { TermsConditions } from "./TermsConditions"

// Form
import * as yup from "yup"
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Functions
import { dateConvert, toast, validation } from "functions"

// Third-Party Libraries
import uuid from "react-uuid"
import { useNavigate } from "react-router-dom"

// Utilities
import { useDataWithWaiver } from "../utils"

export function FormSection({ data }) {
  // Hooks
  const [booking, setBooking] = useState(null)

  return (
    <Fragment>
      <section className="card border rounded-3 shadow-lg">
        <section className="p-3">
          <ModalSection onSetBooking={setBooking} />
        </section>
      </section>

      {booking ? (
        <CodeForm booking={booking} />
      ) : (
        <NoCodeForm data={data} />
      )}
    </Fragment>
  )
}

function CodeForm({ booking }) {
  // Hooks
  const navigate = useNavigate()
  const signatureRef = useRef(null)

  // Form
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      type_waiver: "WALKIN",
      booking_date: dateConvert(new Date()).default.format,
      booking_code: booking.booking_code,
      email: booking.guest_email,
      phone: booking.guest_phone,
      policy: false,
      terms: false,
      waiver: false
    },
    resolver: yupResolver(yup.object().shape({
      email: yup.string().label("Email").email().required(),
      phone: yup.string().label("Phone").required(),
      policy: yup.boolean().label("Policy").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      terms: yup.boolean().label("Terms and Conditions").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      waiver: yup.boolean().label("Waiver").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
    }))
  })
  const onSubmit = values => {
    return new Promise((resolve) => {
      // Variables
      if (signatureRef.current?.isEmpty()) {
        toast("error", "failed", "Signature is empty")
        return resolve()
      }

      OrderApi.store({
        ...values,
        additional_cost: false,
        unique_id: uuid(),
        walk_in: true,
        privacy_policy: false,
        signature: signatureRef.current?.toDataURL('image/png')
      }).then(res => {
        const data = res.data

        navigate(`/success?unique_id=${data.link}`, {
          replace: true,
          state: { barcode: data.barcode }
        })
      }).catch(err => {
        toast("error", "Error", err.response.data.message)
      }).finally(resolve)
    })
  }

  return (
    <FormProvider control={control}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="my-3">
          <section className="card border rounded-3 shadow-lg">
            <section className="card-body">
              <div className="container-md">
                <div className="mb-2">
                  <h5 className="font-bold">Guest Details</h5>

                  <section className="grid md:grid-cols-2">
                    <section className="my-1">
                      <div className="font-bold">Booking Code</div>
                      <div>{booking.booking_code}</div>
                    </section>

                    <section className="my-1">
                      <div className="font-bold">Name</div>
                      <div>{booking.guest_name}</div>
                    </section>

                    <Controller
                      name="phone"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label={<div>Phone <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.phone?.message}
                        />
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          type="email"
                          label={<div>Email <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.email?.message}
                        />
                      )}
                    />
                  </section>

                  <section className="my-1">
                    <div className="font-bold">Email</div>
                    <div>{booking.guest_email}</div>
                  </section>

                  <TermsConditions waiver={<Terms.SplashWaiver />} />
                  <Signature ref={signatureRef} label="Testing" />

                  <div className="text-center mt-2">
                    <ActionButton
                      type="submit"
                      loading={formState.isSubmitting}
                      color="primary"
                      className="text-white"
                      size="lg"
                    >
                      Book Now
                    </ActionButton>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </form>
    </FormProvider>
  )
}

function NoCodeForm({ data }) {
  // Hooks
  const navigate = useNavigate()
  const signatureRef = useRef(null)
  const dataWaiver = useDataWithWaiver(data)

  // Form
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      type_waiver: "WALKIN",
      booking_date: dateConvert(new Date()).default.format,
      ticket: dataWaiver.formData,
      policy: false,
      terms: false,
      email: "",
      first_name: "",
      gender: "",
      last_name: "",
      nationality: 101,
      phone: "",
      qty_insurance: 0,
      waiver: false
    },
    resolver: yupResolver(yup.object().shape({
      terms: yup.boolean().label("Terms and Conditions").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      policy: yup.boolean().label("Policy").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      first_name: yup.string().label("First Name").required(),
      last_name: yup.string().label("Last Name").required(),
      phone: yup.string().label("Phone").required(),
      nationality: yup.string().label("Nationality").required(),
      email: yup.string().label("Email").email(validation().email.label).required(),
      gender: yup.string().label("Gender").required(),
      waiver: yup.boolean().label("Waiver").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
    }))
  })
  const { fields } = useFieldArray({
    control,
    name: 'ticket'
  })
  const onSubmit = values => {
    return new Promise((resolve) => {
      // Variables
      const { ticket, ...restValues }  = values
      const paxTicket = ticket.reduce((acc, val) => {
        // Variables
        let count = 0

        dataWaiver.waiverHeader.map(item => {
          // Variables
          const pax = val[item] ?? 0

          return count += pax
        })

        return acc + count
      }, 0)

      if (signatureRef.current?.isEmpty()) {
        toast("error", "failed", "Signature is empty")
        return resolve()
      }

      if (paxTicket === 0) {
        toast("error", "failed", "No tickets found")
        return resolve()
      } else if (values.qty_insurance > paxTicket) {
        toast("error", "failed", "Personal insurance can't be more than total product")
        return resolve()
      }

      OrderApi.store({
        ...restValues,
        ticket,
        additional_cost: false,
        unique_id: uuid(),
        walk_in: true,
        privacy_policy: false,
        signature: signatureRef.current?.toDataURL('image/png')
      }).then(res => {
        const data = res.data

        navigate(`/success?unique_id=${data.link}`, {
          replace: true,
          state: { barcode: data.barcode }
        })
      }).catch(err => {
        toast("error", "Error", err.response.data.message)
      }).finally(resolve)
    })
  }

  return (
    <FormProvider control={control}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="card border my-3 rounded-3 shadow-lg">
          <section className="card-body">
            <div style={{ maxHeight: '320px', overflow: "auto" }}>
              <table className="table">
                <thead>
                  <tr className="text-center">
                    <th>No</th>
                    <th>Name</th>
                    {dataWaiver.waiverHeader.map((item, key) => <th key={key} className="capitalize" style={{ width: 10 }}>{item}</th>)}
                  </tr>
                </thead>

                <tbody>
                  {fields.map((field, key) => (
                    <tr key={field.id}>
                      <td className="text-center">{key + 1}</td>
                      <td>{field.name}</td>
                      {dataWaiver.waiverHeader.map((item, waiverKey) => {
                        if (item in field) {
                          return (
                            <td key={waiverKey}>
                              <div className="px-50">
                                <Controller
                                  name={`ticket[${key}][${item}]`}
                                  control={control}
                                  render={method => (
                                    <InputNumber
                                      {...method.field}
                                      ref={null}
                                      noLabel
                                      noMargin
                                      min="0"
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          )
                        }

                        return <td key={waiverKey} />
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </section>

        <section className="my-3 card border rounded-3 shadow-lg">
          <section className="card-body">
            <div className="md:container">
              <div className="mb-2">
                <h5 className="font-bold">Guest Details</h5>

                <section className="grid md:grid-cols-4 gap-3">
                  <section className="md:col-span-4">
                    <Controller
                      control={control}
                      name="booking_date"
                      render={({ field }) => <Calendar {...field} ref={null} label="Booking Date" />}
                    />
                  </section>

                  <section className="md:col-span-2">
                    <Controller
                      name="first_name"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label={<div>First Name <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.first_name?.message}
                        />
                      )}
                    />
                  </section>

                  <section className="md:col-span-2">
                    <Controller
                      name="last_name"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label={<div>Last Name <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.last_name?.message}
                        />
                      )}
                    />
                  </section>

                  <section className="md:col-span-2">
                    <Nationality />
                  </section>

                  <section>
                    <Controller
                      name="phone"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label={<div>Phone <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.phone?.message}
                        />
                      )}
                    />
                  </section>

                  <section>
                    <Controller
                      name="zip"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label="Zip/Post Code"
                          ref={null}
                          errors={method.formState.errors?.zip?.message}
                        />
                      )}
                    />
                  </section>

                  <section className="md:col-span-2">
                    <Gender />
                  </section>

                  <section className="md:col-span-2">
                    <Controller
                      name="email"
                      control={control}
                      render={method => (
                        <Input
                          {...method.field}
                          label={<div>Email <span className="text-error">*</span></div>}
                          ref={null}
                          errors={method.formState.errors?.email?.message}
                        />
                      )}
                    />
                  </section>
                </section>

                <TermsConditions waiver={<Terms.SplashWaiver />} />
                <Signature ref={signatureRef} />

                <div className="text-center mt-2">
                  <ActionButton
                    type="submit"
                    loading={formState.isSubmitting}
                    color="primary"
                  >
                    Book Now
                  </ActionButton>
                </div>
              </div>
            </div>
          </section>
        </section>
      </form>
    </FormProvider>
  )
}