// React
import { Fragment, useEffect, useState } from "react"

// Third-Party Library
import moment from "moment"
import { useParams } from "react-router-dom"

// Functions
import { dateConvert, validation } from "functions"

// Components
import { DataStatus } from "components"

// Contexts
import { DataProvider, ValidDateContext } from "./contexts"

// Assets
import "./assets/css/style.css"

// View Components
import { FormSection } from "./components"

// API
import axios from "axios"
import { HomeApi } from "../api"
import { Api } from "./components/OrderReview/api"

// UUID
import uuid from "react-uuid"

// Form
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Utils
import { isFlashSale } from "./utils"

const Category = ({ baseData, setting }) => {
  // States
  const { value } = useParams()
  const [code, setCode] = useState([])
  const [validDate, setValidDate] = useState("")
  const [renderUuid, toggleUuid] = useState(true)

  // Vars
  const date = new Date()
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  const isValid = Boolean(process.env.REACT_APP_VALID_SLUG.includes(value === "flash-sale" ? "splash" : value))
  const is_flash_sale = isFlashSale(value)

  useEffect(() => {
    // Variables
    const dataUuid = localStorage.getItem('uuid')

    const isUuid = value => {
      const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      return regex.test(value)
    }

    if (!(dataUuid && isUuid(dataUuid))) {
      localStorage.setItem('uuid', uuid())

      if (is_flash_sale) {
        HomeApi.promo_list().then(promo => {
          setValidDate(promo[0].valid_date)
        }).finally(() => {
          toggleUuid(false)
        })
      }

      toggleUuid(false)
    } else {
      axios.all([
        Api.getPromo({ unique_id: dataUuid }),
        is_flash_sale && HomeApi.promo_list()
      ]).then(axios.spread((res, promo) => {
        setCode(res.promo)

        if (is_flash_sale) {
          setValidDate(promo[0].valid_date)
        }
      })).finally(() => {
        toggleUuid(false)
      })
    }

    // eslint-disable-next-line
  }, [])

  const ViewSection = ({ baseData, code, setting }) => {
    // Hooks
    const { value } = useParams()

    // Variables
    const category = baseData.category.filter(val => val.slug === value)
    const no_waiver = Boolean(category && category[0]?.waiver_type === "FITNESS")
    const is_departure = Boolean(category ? category[0]?.is_departure ?? 0 : 0)

    // Form
    const isDeparture = () => {
      if (is_departure) {
        return {
          date: moment().add(process.env.REACT_APP_DEPARTURE_AVAILABLE_IN_DAYS, "day"),
          validation: { no_transportation: yup.boolean().label("Transportation Policy").required().oneOf(validation().mustAccept.value, validation().mustAccept.label) }
        }
      }

      return {
        date: moment(),
        validation: null
      }
    }
    const defaultValues = {
      code,
      booking_date: dateConvert(isDeparture().date).default.format,
      page: 1,
      _code: code,
      unique_id: localStorage.getItem("uuid") ?? uuid(),
      policy: false,
      terms: false,
      date_of_birth: moment().format("YYYY-MM-DD"),
      email: "",
      email_confirmation: "",
      first_name: "",
      gender: "",
      last_name: "",
      nationality: 101,
      phone: "",
      additional_cost: false,
      no_transportation: false,
      waiver: no_waiver,
      privacy_policy: false,
      qty_insurance: 0,
      is_date_available: false,
      ticket: []
    }
    const validationSchema = yup.object().shape({
      terms: yup.boolean().label("Terms and Conditions").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      policy: yup.boolean().label("Policy").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      first_name: yup.string().label("First Name").required(),
      last_name: yup.string().label("Last Name").required(),
      phone: yup.number().label("Phone").typeError(validation().number.label).required(),
      nationality: yup.string().label("Nationality").required(),
      email: yup.string().label("Email").email(validation().email.label).required(),
      email_confirmation: yup.string().label("Email Confirmation").oneOf([yup.ref("email")], validation(["Email Confirmation", "Email"]).exact.label).required(),
      date_of_birth: yup.date().label("Date of Birth").required(),
      gender: yup.string().label("Gender").required(),
      waiver: yup.boolean().label("Splash Waiver").required().oneOf(validation().mustAccept.value, validation().mustAccept.label),
      ...isDeparture().validation
    })
    const methods = useForm({
      defaultValues,
      resolver: yupResolver(validationSchema)
    })

    return (
      <Fragment>
        <section className="my-5 container">
          <h2 className="font-bold text-center">Make A Day Pass Booking</h2>
        </section>
  
        <section className="my-3">
          <DataProvider
            start_page={1}
            isValid={isValid}
          >
            <FormProvider {...methods}>
              <FormSection
                baseData={baseData}
                category={category}
                setting={setting}
                min_date={dateConvert(isDeparture().date).default.format}
              />
            </FormProvider>
          </DataProvider>
        </section>
      </Fragment>
    )
  }

  if (isValid) {
    if (today > new Date(process.env.REACT_APP_VALID_DATE)) {
      return <DataStatus>No data available</DataStatus>
    }
  }

  if (renderUuid) return <DataStatus loading>Loading...</DataStatus>

  return (
    <ValidDateContext.Provider value={validDate}>
      <ViewSection
        baseData={baseData}
        code={code}
        setting={setting}
      />
    </ValidDateContext.Provider>
  )
}

export default Category