// React
import { createContext } from "react"

export const DataContext = createContext({
  start_page: 0,
  isValid: false
})

export function DataProvider({ children, isValid, start_page }) {
  return (
    <DataContext.Provider value={{ isValid, start_page }}>{children}</DataContext.Provider>)
}