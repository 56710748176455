import { Category, Home, PaymentSuccess } from "views/dayPassesView"

const menu = [
  {
    link: "/",
    component: Home
  },
  {
    link: "/:value",
    component: Category
  },
  {
    link: "/success",
    component: PaymentSuccess
  },
  {
    link: "/thank-you/:slug",
    component: PaymentSuccess
  }
]

export default menu