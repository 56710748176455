// Assets
import blank from "assets/images/blank_canvas.svg"

// Components
import { ActionButton } from "components"

// Icons
import { AiOutlineHome } from "react-icons/ai"

// Third-Party Library
import { useNavigate } from "react-router-dom"

// Config
import { logo } from "Config"

const PageNotFound = () => {
  // Hooks
  const navigate = useNavigate()

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <section className="max-w-[500px] mb-5 text-center">
        <img alt="Logo" src={logo} height="80" />
        <h3 className="font-extrabold">404 - Page Not Found</h3>

        <ActionButton color="primary" className="text-white" onClick={() => navigate("/")}>
          <AiOutlineHome /> Home
        </ActionButton>

        <img alt="Page Not Found" src={blank} className="w-full" />
      </section>
    </div>
  )
}

export default PageNotFound