import service from "service"
import { mappingData } from "functions"

const base_url = process.env.REACT_APP_BASE_URL_API
class AtributApi {
  async countries() {
    const data = await service.get(`${base_url}/countries`)

    return mappingData(data.data.data)
  }

  async currency_rate() {
    const data = await service.get(`${base_url}/currency-rate`)

    return mappingData(data.data.data, 'currency_code', 'id')
  }

  async payment_methods () {
    const data = await service.get(`${base_url}/payment-methods`)

    return data.data.data
  }

  async genders () {
    const data = await service.get(`${base_url}/booking/genders`)

    return mappingData(data.data.data)
  }
}

export default new AtributApi()