// React
import { useRef } from "react"

// Components
import { PhotoSection } from "./components"

// Configs
import { finnsrec } from "Config"

// Third-Party Libraries
import PropTypes from "prop-types"
import { Link, useParams } from "react-router-dom"

// Utils
import { getCategoryProps, name_list, useCategory } from "./utils"

export default function Inclusions() {
  // Hooks
  const { slug } = useParams()
  const props = getCategoryProps(slug)
  const { data, isLoading } = useCategory(slug)

  return (
    <section className="relative">
      <section className={`${props.hasDarkMode ? "bg-black" : "bg-white"} lg:hidden block flex justify-center`}>
        <Link to="/">
          <img src={props.hasDarkMode ? finnsrec.white : finnsrec.black} alt="FBC Black" className="h-10 my-3" />
        </Link>
      </section>

      <section className="lg:block hidden absolute inset-x-0 top-[10px] z-10">
        <section className="container">
          <Link to="/">
            <img src={finnsrec.white} alt="FBC White" className="h-10" />
          </Link>
        </section>
      </section>

      {isLoading ? (
        <section>
          <div className="w-full aspect-[16/9] bg-gray-500 animate-pulse" />

          <section>
            <section className="container lg:py-16 py-3 grid lg:grid-cols-2 items-center gap-3">
              <section className="flex flex-col gap-3">
                <section className="flex flex-col lg:items-start items-center">
                  <div className="bg-gray-500 animate-pulse rounded-lg h-8 w-full max-w-[160px]" />
                  <div className="bg-gray-500 animate-pulse rounded-lg h-10 w-full max-w-[240px]" />
                  <div className="bg-gray-500 animate-pulse rounded-lg h-10 w-full max-w-[320px]" />
                </section>

                <section className="flex flex-col lg:items-start items-center gap-1">
                  {[...Array(3)].map((_, key) => <div key={key} className="bg-gray-500 animate-pulse rounded-lg h-4 w-full" />)}
                  <div className="bg-gray-500 animate-pulse rounded-lg h-4 w-[200px]" />
                </section>

                <section className="flex flex-col lg:items-start items-center">
                  <div className="bg-gray-500 animate-pulse rounded-full h-10 w-24" />
                </section>
              </section>

              <section className="grid lg:grid-cols-3 gap-3">
                {[...Array(3)].map((_, key) => <div key={key} className="bg-gray-500 animate-pulse rounded-lg w-full aspect-[7/8]" />)}
              </section>
            </section>
          </section>

          <section className="bg-gray-200">
            <section className="container lg:py-16 py-3 flex flex-col items-center gap-10">
              <div className="bg-gray-500 animate-pulse rounded-lg h-10 w-full max-w-[320px]" />

              <section className="w-full max-w-[600px] flex flex-col gap-3">
                {[...Array(3)].map((_, key) => (
                  <section key={key} className="flex gap-2">
                    <div className="bg-gray-500 animate-pulse h-4 w-4" />
                    <div className="grow bg-gray-500 animate-pulse rounded-lg h-4 w-full" />
                  </section>
                ))}
              </section>
            </section>
          </section>
        </section>
      ) : (
        <section className={`${props.hasDarkMode ? "bg-black text-white" : "bg-white"}`}>
          <section className="relative">
            <video src={`${process.env.REACT_APP_BASE_URL}${data.video360}`} className={`w-full ${props.color.background}`} autoPlay loop muted />
  
            {!props.hideTitle && (
              <section className={`${props.title_style ?? "justify-center text-center"} absolute inset-x-0 top-[80px] container lg:flex hidden`}>
                <section className={`${props.hasLongTitle ? "w-full" : "w-[300px]"} ${props.title_style ?? "text-center"} text-white leading-[0.8]`}>
                  <div className="text-[40px] font-extralight">{props.title[0]}</div>
                  <div className="text-[70px] font-bold">{props.title[1]}</div>
                  <div className="text-[70px] font-light">{props.title[2]}</div>
                </section>
              </section>
            )}
          </section>
  
          <section className={`${!props.hasBottomContent && "grid lg:grid-cols-5"} container lg:py-16 py-5 items-center gap-3`}>
            <section className={`${props.hasBottomContent ? "items-center max-w-[1000px] mx-auto" : "lg:col-span-2"} flex flex-col gap-5 text-center justify-center`}>
              <section className={`${!props.hasBottomContent && "lg:text-left lg:justify-start"} leading-[0.8] ${props.hasTwoWords ? "lg:w-full w-[300px]" : props.hasLongTitle ? "w-full" : "w-[350px]"} lg:mx-0 mx-auto flex flex-col text-center justify-center`}>
                <div className="text-[40px] font-extralight">{props.title[0]}</div>
                <div className={`text-[70px] font-bold ${props.color.text}`}>{props.title[1]}</div>
                <div className="text-[70px] font-light">{props.title[2]}</div>
              </section>
              
              <div className="lg:text-left" dangerouslySetInnerHTML={{ __html: data.description }} />
              <BookNow color={props.color.background} hasBottomContent={props.hasBottomContent} slug={slug} />
            </section>
  
            {!props.hasBottomContent ? (
              <section className="lg:col-span-3">
                {props.hasCompany ? (
                  <section className="grid lg:grid-cols-3 grid-cols-2 gap-3">
                    {data.details.map((item, key) => (
                      <section key={key} className="flex flex-col gap-1 text-center">
                        <img src={item.company.background} alt={`${item.company.name}-background`} className="w-full rounded-xl aspect-[2/3] object-cover" />
                        <img src={item.company.logo} alt={`${item.company.name}-logo`} className="w-full rounded-xl aspect-[16/5] object-contain" />
                      </section>
                    ))}
                  </section>
                ) : (
                  <section className="grid lg:grid-cols-3 gap-3">
                    {data.menu.map((item, key) => (
                      <section key={key} className="flex flex-col gap-1 text-center">
                        <img src={item.image} alt={item.title} className="w-full rounded-xl aspect-[2/3] object-cover" />
                        <div className={`!mb-0 text-xl font-bold ${props.color.text}`}>{props.menu[key]?.title}</div>
                        <div>{props.menu[key]?.description}</div>
                      </section>
                    ))}
                  </section>
                )}
              </section>
            ) : (
              <MenuImages item={data.menu} />
            )}
          </section>
  
          <section className={`${props.hasDarkMode ?? "bg-gray-200"}`}>
            <section className="container lg:py-16 py-5 flex flex-col items-center gap-10">
              <h1 className="font-bold text-center">Inclusions</h1>
              <div className={`max-w-[1000px] mx-auto prose ${props.list_style}`} dangerouslySetInnerHTML={{ __html: data.detail2 }} />
            </section>
          </section>
  
          <section className="container lg:py-16 py-5 flex flex-col items-center gap-10">
            <h1 className="font-bold text-center">Pricing</h1>
  
            <section className="flex flex-wrap justify-center gap-3">
              {data.online_service.map((item, key) => {
                const name = item.name.split("(")
  
                if (props.hasCustomPricing && name_list.includes(item.name)) {
                  if (item.name === "FINNS FITNESS CENTRE DAY PASS (6AM-9PM)") {
                    return (
                      <FitnessItem
                        key={key}
                        bg="bg-[#190606]"
                        item={item}
                        title={(
                          <section>
                            <div className="text-2xl">FINNS</div>
                            <div className="font-bold text-6xl text-[#E84949] leading-[0.8]">FITNESS<br />CENTRE</div>
                            <div className="text-4xl">DAY PASS</div>
                          </section>
                        )}
                      />
                    )
                  } else if (item.name === "FINNS FITNESS CENTRE OFF PEAK PASS (5PM-9PM)") {
                    return (
                      <FitnessItem
                        key={key}
                        bg="bg-[#00AEB71A]"
                        item={item}
                        title={(
                          <section>
                            <div className="text-2xl">FINNS</div>
                            <div className="font-bold text-6xl text-[#00AEB7] leading-[0.8]">FITNESS<br />CENTRE</div>
                            <div className="text-4xl">OFF PEAK PASS</div>
                          </section>
                        )}
                      />
                    )
                  }
                }

                return (
                  <div key={key} className={`self-stretch w-[350px] rounded-3xl px-10 py-16 flex flex-col justify-between gap-3 ${props.color.background_light}`}>
                    <section>
                      <div className={`font-bold text-4xl mb-2 ${props.color.text}`}>{name[0]}</div>
                      {name[1] && <h5 className="text-secondary">*{name[1].replace(")", "")}</h5>}
                    </section>
  
                    <div className="font-bold text-4xl">
                      <sup>IDR </sup> {item.price.toLocaleString()}
                    </div>
                  </div>
                )
              })}
            </section>

            <BookNow color={props.color.background} slug={slug} />
          </section>
  
          {props.hasPhoto && <PhotoSection />}
        </section>
      )}
    </section>
  )
}

function BookNow(props) {
  return (
    <Link to={`/${props.slug}`} className={`${!props.hasBottomContent && "lg:justify-start"} flex justify-center`}>
      <button className={`w-fit btn rounded-full ${props.color}`}>Book Now</button>
    </Link>
  )
}
BookNow.propTypes = {
  color: PropTypes.string,
  hasBottomContent: PropTypes.bool,
  slug: PropTypes.string
}

function FitnessItem({ bg, item, title }) {
  // Vars
  const name = item.name.split("(")

  return (
    <div className={`self-stretch w-[350px] rounded-3xl px-10 flex flex-col justify-between gap-3 ${bg}`}>
      <section className="text-center mb-5 pt-5 flex flex-col gap-1">
        {title}
        <div className="text-xl text-secondary">FROM {name[1].replace(")", "")}</div>
      </section>

      <div className="font-bold text-4xl pb-10">
        <sup>IDR </sup> {item.price.toLocaleString()}
      </div>
    </div>
  )
}

function MenuImages(props) {
  // Hooks
  const ref = useRef()

  return (
    <section
      ref={ref}
      className={`flex gap-3 my-5 overflow-auto ${ref.current?.scrollWidth > ref.current?.clientWidth ? "justify-start" : "justify-center"}`}
    >
      {props.item.map((item, key) => <img key={key} src={item.image} alt={`menu-${key + 1}`} className="h-[200px] rounded-xl object-cover" />)}
    </section>
  )
}
MenuImages.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.string.isRequired
    })
  )
}
