// ** Flatpickr
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/flatpickr.css"

// Functions
import { dateConvert } from "functions"

export const Calendar = ({
  value,
  onChange,
  label = "",
  noLabel = false,
  errors,
  ...rest
}) => (
  <div className="form-control w-full">
    {label && <span className="label-text font-bold">{label}</span>}

    <div>
      <Flatpickr
        {...rest}
        value={new Date(value)}
        onChange={e => onChange(dateConvert(e).default.format)}
        className={`input input-bordered w-full ${errors && 'input-error'}`}
      />
    </div>

    {errors && <small className="text-error">{errors}</small>}
  </div>
)